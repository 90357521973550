import React, { FC } from 'react';

import { validateHTML } from '../../helpers/validators';
import Sections from '../../components/Sections/Sections';
import PageHead from '../../components/PageHead/PageHead';
import { StandardData } from '../../../types/page';

import './StandardPage.scss';

interface StandardPageProps {
  data?: StandardData;
}

const StandardPage: FC<StandardPageProps> = ({ data }) => {
  if (!data) return null;

  const additionalHtml = validateHTML(data.content);

  return (
    <div>
      <PageHead title={data?.name} />
      <div className="main-content mt-8">
        <Sections sections={data.sections} />

        {!!additionalHtml && (
          <div className="additional-html" dangerouslySetInnerHTML={{ __html: additionalHtml }} />
        )}
      </div>
    </div>
  );
};

export default StandardPage;
