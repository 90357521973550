import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import React, { FC, useEffect, useMemo } from 'react';
import moment from 'moment';
import { Flex } from '@brightcove/studio-components';

import VideoDetailTab from '../VideoDetailTab/VideoDetailTab';
import { useInteraction } from '../InteractionContext/InteractionContenxt';
import Button from '../Button/Button';
import {
  ON_CUEPOINT_SELECTED,
  millisecondsToTimeString,
  getUrlPath,
  isSameDomain,
  isValidCta,
} from '../../utils';
import { setCurrentVideoProgress } from '../../store/slices/configSlice';
import { selectLogged } from '../../store/selectors/authSelectors';
import { getLabelForVideoType } from '../../helpers/utils';
import { VideoDetailSection } from '../../../types/section';
import { Cuepoint } from '../../../types/generic';
import './VideoDetail.scss';

interface VideoDetailProps {
  section: VideoDetailSection;
}

const VideoDetail: FC<VideoDetailProps> = ({
  section: {
    name,
    description,
    productionYear,
    duration,
    videoType,
    cta,
    speakers,
    resources,
    progress,
    cuepoints,
  },
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(selectLogged);
  const { handleInteraction } = useInteraction();

  const filteredResources = resources.filter((resource) => isLoggedIn || !resource.needsAuth);

  const tabContent = [
    {
      id: 'speakers',
      title: 'Speakers',
      content: speakers,
    },
    ...(filteredResources.length
      ? [
          {
            id: 'resources',
            title: 'Related resources',
            content: filteredResources,
          },
        ]
      : []),
  ];

  useEffect(() => {
    dispatch(setCurrentVideoProgress(progress));
  }, [progress]);

  const showTabs = useMemo(() => speakers?.length > 0 || resources?.length > 0, [speakers, resources]);

  return (
    <div className="VideoDetail ">
      <Flex className="VideoDetail-info" flexDirection="column">
        <h2 className="VideoDetail-info-title">{name}</h2>
        <Flex className="VideoDetail-info-metadata" alignItems="center">
          {productionYear && <span>{productionYear}</span>}
          {duration && <span>{millisecondsToTimeString(duration)}</span>}
          {videoType && <span>{getLabelForVideoType(videoType)}</span>}
        </Flex>
        <h6 className="VideoDetail-info-description">{description}</h6>
        {cuepoints.length > 0 ? (
          <div className="mt-2">
            {cuepoints.map((cuepoint: Cuepoint) => {
              const d = moment.utc(cuepoint.time * 1000);
              const time = d.hours() ? d.format('HH:mm:ss') : d.format('mm:ss');

              return (
                <div className="VideoDetail-cuepoint" key={`${cuepoint.name}_${cuepoint.time}`}>
                  <div
                    className="VideoDetail-cuepoint-time"
                    onClick={() => handleInteraction({ [ON_CUEPOINT_SELECTED]: cuepoint.time })}
                  >
                    {time}
                  </div>
                  <div className="VideoDetail-cuepoint-name">{cuepoint.name}</div>
                </div>
              );
            })}
          </div>
        ) : null}
      </Flex>
      {isValidCta(cta) ? (
        <div className={`VideoDetail-buttons-container ${!showTabs ? 'mb-8' : ''}`}>
          <Button
            text={cta.label}
            variant="primary"
            onClick={() => {
              const destination = cta.url;

              if (isSameDomain(destination)) {
                navigate(getUrlPath(destination));
              } else {
                window.open(destination, '_blank');
              }
            }}
            className="mt-5"
          />
        </div>
      ) : null}
      {showTabs ? <VideoDetailTab tabContent={tabContent} className="mt-12" /> : null}
    </div>
  );
};

export default VideoDetail;
