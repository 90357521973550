import { createSelector } from '@reduxjs/toolkit';

import { ConfigState } from '../slices/configSlice';
import { RootState } from '../index';

// Select the config slice state
const selectConfig = (state: RootState): ConfigState => state.config;

// Select specific properties from the config state
// With this approach we just get the config instead of doing state.config.displayLogIn
export const selectDisplayLogIn = createSelector(selectConfig, (config) => config.displayLogIn);

export const selectLanguages = createSelector(selectConfig, (config) => config.languages);

export const selectSiteLanguage = createSelector(selectConfig, (config) => config.siteLanguage);

export const selectSiteLanguageLong = createSelector(selectConfig, (config) => config.siteLanguageLong);

export const selectSiteBaseUrl = createSelector(selectConfig, (config) => config.siteBaseUrl);

export const selectSiteConfig = createSelector(selectConfig, (config) => config.siteConfig);

export const selectPreviewToken = createSelector(selectConfig, (config) => config.previewToken);

export const selectMaintenanceMode = createSelector(selectConfig, (config) => config.maintenanceMode);

export const selectFavorites = createSelector(selectConfig, (config) => config.favorites);

export const selectFavoriteLoadingId = createSelector(selectConfig, (config) => config.favoriteLoadingId);

export const selectContinuity = createSelector(selectConfig, (config) => config.continuity);

export const selectLoadingLanguage = createSelector(selectConfig, (config) => config.loadingLanguage);

export const selectCurrentVideoProgress = createSelector(
  selectConfig,
  (config) => config.currentVideoProgress
);
