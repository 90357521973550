import { useTranslation } from 'react-i18next';
import React, { ChangeEvent, useState, KeyboardEvent, FC, useEffect } from 'react';
import classnames from 'classnames';
import { Icon } from '@brightcove/studio-components';
import './SearchInput.scss';

interface SearchInputProps {
  onSearch: (value: string) => void;
  className?: string;
  value?: string;
  clearInput?: boolean;
}

const SearchInput: FC<SearchInputProps> = ({ onSearch, className, clearInput = false, value = '' }) => {
  const [inputValue, setInputValue] = useState(value);
  const { t } = useTranslation();

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setInputValue(event.target.value);
  };

  const onSearchValue = (): void => {
    onSearch(inputValue);

    if (clearInput) setInputValue('');
  };

  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Enter') {
      onSearchValue();
    }
  };

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <div className={classnames('Search-wrapper', className)}>
      <input
        className="Search-input"
        value={inputValue}
        type="text"
        placeholder={t('header_search_placeholder') || ''}
        onChange={handleChange}
        onKeyDown={handleKeyPress}
      />
      <div onClick={onSearchValue} className="Search-icon">
        <Icon name="SEARCH" />
      </div>
    </div>
  );
};

export default SearchInput;
