import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import React, { FC, useEffect } from 'react';
import classnames from 'classnames';

import { getLanguageCode, supportedLanguages } from '../../utils';
import { setLoadingLanguage, setSiteConfig, setSiteLanguageInfo } from '../../store/slices/configSlice';
import { useUpdateUserProfileMutation } from '../../store/services/user';
import { useLazyGetConfigMetaQuery } from '../../store/services/metadata';
import { selectSiteLanguage } from '../../store/selectors/configSelectors';
import { selectUser } from '../../store/selectors/authSelectors';
import { useLanguageChange } from '../../hooks/useLanguageChange';
import ContainerWithTitle from '../../components/ContainerWithTitle/ContainerWithTitle';

import './Language.scss';

const Language: FC = () => {
  const { t, i18n } = useTranslation();
  const currentLang = useSelector(selectSiteLanguage);
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const { changeLanguage } = useLanguageChange();

  const [updateUserProfile] = useUpdateUserProfileMutation();
  const [getConfig, { data }] = useLazyGetConfigMetaQuery();

  const onSelectLanguage = async (value: string): Promise<void> => {
    dispatch(setLoadingLanguage(true));
    changeLanguage(value, true);

    if (user) {
      await updateUserProfile({ languagePref: value });
    }

    await getConfig({ lang: value });
  };

  useEffect(() => {
    if (data) {
      const languageCode = getLanguageCode(data.language);
      i18n.changeLanguage(currentLang);
      dispatch(setSiteConfig(data));
      dispatch(setSiteLanguageInfo(languageCode));
      i18n.addResources(languageCode, 'translation', data.labels);
    }

    dispatch(setLoadingLanguage(false));
  }, [data, currentLang]);

  return (
    <ContainerWithTitle
      containerClass="Language"
      wrapperClass="Language-wrapper"
      title={t('header_language') || ''}
    >
      <div className="Language-container">
        {supportedLanguages.map(({ label, value }) => {
          const isCurrentLang =
            currentLang === getLanguageCode(value) || (currentLang === '' && value === 'en-us');

          return (
            <div
              key={value}
              className={classnames('Language-box', { 'Language-box-active': isCurrentLang })}
              onClick={() => onSelectLanguage(value)}
            >
              <div className="Language-text">{label}</div>
              <input
                type="radio"
                className="Language-radio"
                value={value}
                checked={isCurrentLang}
                onChange={() => onSelectLanguage(value)}
              />
            </div>
          );
        })}
      </div>
    </ContainerWithTitle>
  );
};

export default Language;
