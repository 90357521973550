const config = {
  env: process.env.NODE_ENV,
  version: process.env.REACT_APP_VERSION,
  environment: process.env.REACT_APP_ENVIRONMENT,
  aws_region: process.env.REACT_APP_AWS_REGION,
  api_url: process.env.REACT_APP_API_URL,
  account_id: process.env.REACT_APP_BC_ACCOUNT_ID,
  player_id: process.env.REACT_APP_BC_PLAYER_ID,
  update_progress_url: process.env.REACT_APP_UPDATE_PROGRESS_URL,
  adobe_analytics: {
    config: {
      qa: {
        marketingCloudOrgId: '1D6F34B852784AA40A490D44@AdobeOrg',
        namespace: 'networkappliance',
        rsid: 'networkapplglobalexternal-dev',
        trackingServer: 'sometrics.netapp.com',
      },
      production: {
        marketingCloudOrgId: '1D6F34B852784AA40A490D44@AdobeOrg',
        namespace: 'networkappliance',
        rsid: 'networkapplglobalexternal-dev',
        trackingServer: 'sometrics.netapp.com',
      },
    },
    paramsToTrack: {
      pageTitle: 'eVar8',
      pageUrl: 'eVar31',
      canonicalUrl: 'eVar185',
      pageLanguage: 'eVar148', // User selected lang
      userLanguage: 'eVar186', // Browser's lang
      userId: 'eVar67',
      authenticatedUserId: 'eVar169',
      pageType: 'eVar159',
      errorPage: 'eVar167', // only for error page
      searchTerm: 'eVar1',
      domain: 'server',
    },
    eventsToTrack: {
      supplementalDownload: 'event67',
      ctaClick: 'event130',
      linkClick: 'event149',
    },
    linkClickVars: {
      linkId: 'eVar14',
      linkContainer: 'eVar140',
      linkDestination: 'eVar141',
    },
    ctaClickVars: {
      ctaId: 'eVar106',
      ctaContainer: 'eVar107',
      ctaDestination: 'eVar141',
    },
    authCookieName: '__ntap_global_id',
  },
};

export default config;
