import React, { FC } from 'react';
import './SectionHeader.scss';

interface SectionHeaderProps {
  title: string;
}

const SectionHeader: FC<SectionHeaderProps> = ({ title }) => <div className="SectionHeader">{title}</div>;

export default SectionHeader;
