import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { encodeURLParams, getFullLanguageCode, supportedLanguages } from '../../utils';
import config from '../../config';
import { TileItem } from '../../../types/item';
import { ContinuityData, GenericData } from '../../../types/generic';

type FavoritesData = GenericData & {
  items: TileItem[];
  updated_at: string;
};

type WatchingData = GenericData & {
  items: TileItem[];
  updated_at: string;
};

interface Args {
  params: {
    filter?: string;
    page?: number;
  };
}

export const userApi = createApi({
  reducerPath: 'user',
  baseQuery: fetchBaseQuery({
    baseUrl: `${config.api_url}/user`,
    prepareHeaders: (headers) => {
      const language = localStorage.getItem('lang') || 'en-us';
      headers.set('user-language', getFullLanguageCode(language, supportedLanguages) as string);

      return headers;
    },
    paramsSerializer: encodeURLParams,
    credentials: 'include',
  }),

  endpoints: (builder) => ({
    getUserProfile: builder.query({
      query: () => 'profile',
    }),

    updateUserProfile: builder.mutation<unknown, { languagePref: string }>({
      query: (updatedProfile) => ({
        url: 'profile',
        method: 'PATCH',
        body: updatedProfile,
      }),
    }),

    getUserContinuity: builder.query<ContinuityData, object>({
      query: () => ({ url: `continuity` }),
    }),

    getUserFavorites: builder.query<FavoritesData, Args>({
      query: ({ params }) => ({ url: `profile/favorites`, params }),
    }),

    getUserWatching: builder.query<WatchingData, Args>({
      query: ({ params }) => ({ url: `profile/watching`, params }),
    }),

    getUserInteractions: builder.query({
      query: () => 'interactions',
    }),

    addUserFavorite: builder.mutation<Record<string, string>, { id: string; action: 'add' | 'remove' }>({
      query: (favorite) => ({
        url: 'favorites',
        method: 'PUT',
        body: favorite,
      }),
    }),

    updateUserProgress: builder.mutation({
      query: (progress) => ({
        url: 'progress',
        method: 'PUT',
        body: progress,
      }),
    }),

    deleteUser: builder.mutation({
      query: (userId) => ({
        url: userId,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useGetUserProfileQuery,
  useUpdateUserProfileMutation,
  useGetUserFavoritesQuery,
  useGetUserWatchingQuery,
  useGetUserContinuityQuery,
  useGetUserInteractionsQuery,
  useAddUserFavoriteMutation,
  useUpdateUserProgressMutation,
  useDeleteUserMutation,
} = userApi;
