import React, { FC } from 'react';

import VideoDetail from '../VideoDetail/VideoDetail';
import SeriesDetail from '../SeriesDetail/SeriesDetail';
import SearchBar from '../SearchBar/SearchBar';
import ReactPlayer from '../ReactPlayer/ReactPlayer';
import { InteractionProvider } from '../InteractionContext/InteractionContenxt';
import Grid from '../Grid/Grid';
import CustomSection from '../CustomSection/CustomSection';
import Carousel from '../Carousel/Carousel';
import Hero from '../../routes/Home/components/Hero/Hero';
import HeroEvent from '../../routes/Events/components/HeroEvent/HeroEvent';
import {
  CarouselSection,
  CustomSectionType,
  EventHeroSection,
  GridSection,
  HeroSection,
  PlayerSection,
  SearchBarSection,
  Section,
  SeriesDetailSection,
  VideoDetailSection,
} from '../../../types/section';

interface SectionsProps {
  sections: Section[] | undefined;
}

const Sections: FC<SectionsProps> = ({ sections }) => (
  <InteractionProvider>
    {sections?.map((section, index) => {
      switch (section.type) {
        case 'Hero':
          return <Hero key={index} section={section as HeroSection} />;
        case 'EventHero':
          return <HeroEvent key={index} section={section as EventHeroSection} />;
        case 'Carousel':
          return <Carousel key={index} section={section as CarouselSection} />;
        case 'Grid':
          return <Grid key={index} section={section as GridSection} />;
        case 'SearchBar':
          return <SearchBar key={index} {...(section as SearchBarSection)} />;
        case 'Player':
          return <ReactPlayer key={index} section={section as PlayerSection} sections={sections} />;
        case 'SeriesDetail':
          return <SeriesDetail key={index} section={section as SeriesDetailSection} />;
        case 'Custom':
          return <CustomSection key={index} section={section as CustomSectionType} />;
        case 'VideoDetail':
          return <VideoDetail key={index} section={section as VideoDetailSection} />;
        default:
          return null;
      }
    })}
  </InteractionProvider>
);

export default Sections;
