export const HOME = '/';
export const SETTINGS = '/settings';
export const SEARCH = '/search';
export const MY_PROFILE = '/myprofile';
export const LOGIN = '/login';
export const AUTH_CALLBACK = '/cb';
export const SIGN_UP = '/sign-up';
export const LOGOUT = '/logout';
export const LANGUAGE = '/language';
export const ABOUT = '/about';
export const EVENTS = '/events';
export const EVENT = '/event';
export const VIDEO_DETAIL = '/video-detail';
export const SECTION_DETAIL = '/section';
export const SERIES_DETAIL = '/series-detail';
export const DYNAMIC = '/page/:id/:slug?';
export const COLLECTION = '/collection';

export const allRoutes = [
  HOME,
  SETTINGS,
  SEARCH,
  MY_PROFILE,
  LOGIN,
  SIGN_UP,
  LOGOUT,
  LANGUAGE,
  ABOUT,
  EVENTS,
  EVENT,
  VIDEO_DETAIL,
  SECTION_DETAIL,
  SERIES_DETAIL,
  DYNAMIC,
  COLLECTION,
];
