import { useNavigate } from 'react-router';
import { MouseEvent } from 'react';

import { getUrlPath, isSameDomain } from '../utils';

interface useDynamicContentClickHandlerReturn {
  onDynamicContentClick: (event: MouseEvent, containerName: string) => void;
}

export const useDynamicContentClickHandler = (): useDynamicContentClickHandlerReturn => {
  const navigate = useNavigate();

  const onDynamicContentClick = (event): void => {
    const targetLink = event.target.closest('a');

    if (!targetLink) return;

    event.preventDefault();

    const destination = targetLink.href;

    if (isSameDomain(destination)) {
      navigate(getUrlPath(destination));
    } else {
      window.open(destination, targetLink.target);
    }
  };

  return {
    onDynamicContentClick,
  };
};
