import React, { FC, useState } from 'react';
import classnames from 'classnames';
import './TextInput.scss';

interface TextInputProps {
  onChange: (val: string, property?: string) => void;
  property?: string;
  value: string | number;
  className?: string;
  placeholder?: string;
  disabled?: boolean;
  type?: 'text' | 'password';
  togglePassword?: boolean;
}
const TextInput: FC<TextInputProps> = ({
  onChange,
  property,
  value,
  className,
  placeholder,
  disabled,
  type = 'text',
  togglePassword,
}) => {
  const [inputType, setInputType] = useState(type);

  return (
    <span className={'TextInput-container'}>
      <input
        disabled={disabled}
        type={inputType}
        className={classnames('TextInput', className)}
        value={value}
        placeholder={placeholder}
        onChange={(e) => onChange(e.target.value, property)}
      />
      {togglePassword ? (
        <span
          className="TextInput-password-toggle"
          onClick={() => {
            inputType === 'text' ? setInputType('password') : setInputType('text');
          }}
        ></span>
      ) : null}
    </span>
  );
};

export default TextInput;
