import React, { createContext, useContext, useState } from 'react';

const InteractionContext = createContext({
  interactionState: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
  handleInteraction: (interactionData: unknown) => {},
});

export const useInteraction = (): {
  interactionState: null;
  handleInteraction: (interactionData: unknown) => void;
} => useContext(InteractionContext);

export const InteractionProvider = ({ children }): JSX.Element => {
  const [interactionState, setInteractionState] = useState(null);

  const handleInteraction = (interactionData): void => {
    setInteractionState(interactionData);
  };

  return (
    <InteractionContext.Provider value={{ interactionState, handleInteraction }}>
      {children}
    </InteractionContext.Provider>
  );
};
