import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import React, { FC } from 'react';

import VideoDetail from '../VideoDetail/VideoDetail';
import SeriesDetailPage from '../SeriesDetailPage/SeriesDetailPage';
import Search from '../Search/Search';
import HomePage from '../Home/Home';
import Event from '../Events/Events';
import EventDetail from '../Events/EventDetail';
import ErrorPage from '../ErrorPage/ErrorPage';
import { useGetPageByIdQuery } from '../../store/services/page';
import { selectPreviewToken } from '../../store/selectors/configSelectors';
import { selectValidated } from '../../store/selectors/authSelectors';
import { useDisablePage } from '../../hooks/useDisablePage';
import DataStatusHandler from '../../components/DataStatusHandler/DataStatusHandler';
import { SearchData } from '../../../types/search';
import {
  ErrorData,
  EventData,
  EventDetailData,
  HomeData,
  SeriesDetailData,
  SettingsData,
  StandardData,
  VideoData,
} from '../../../types/page';

import StandardPage from './StandardPage';
import CustomPage from './CustomPage';

const DynamicPage: FC = () => {
  const params = useParams();
  const pvtk = useSelector(selectPreviewToken);
  const isValidated = useSelector(selectValidated);
  const { data, isLoading, isError } = useGetPageByIdQuery(
    {
      id: params.id,
      ...(pvtk ? { params: { pvtk } } : {}),
    },
    {
      refetchOnMountOrArgChange: true,
      skip: isValidated === null,
    }
  );

  useDisablePage(!!(data?.isPreview && pvtk));

  const renderContent = (): JSX.Element | null => {
    switch (data?.type) {
      case 'CustomPage':
        return <CustomPage data={data as SettingsData} />;
      case 'DetailPage':
        return <VideoDetail customData={data as VideoData} />;
      case 'Error404Page':
        return <ErrorPage customData={data as ErrorData} />;
      case 'EventLandingPage':
        return <Event customData={data as EventData} />;
      case 'EventDetailPage':
      case 'EventPage':
        return <EventDetail customData={data as EventDetailData} />;
      case 'HomePage':
        return <HomePage customData={data as HomeData} />;
      case 'SearchPage':
        return <Search customData={data as SearchData} />;
      case 'SeriesPage':
        return <SeriesDetailPage customData={data as SeriesDetailData} />;
      case 'StandardPage':
        return <StandardPage data={data as StandardData} />;
      default:
        return <ErrorPage customData={data as ErrorData} />;
    }
  };

  return (
    <DataStatusHandler isLoading={isLoading} isError={isError}>
      {renderContent()}
    </DataStatusHandler>
  );
};

export default DynamicPage;
