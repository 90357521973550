import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import debounce from 'lodash.debounce';
import { uniqBy } from 'lodash';
import { Flex } from '@brightcove/studio-components';

import { contentFilters } from '../MyProfile';
import { DEFAULT_PAGE } from '../../../utils';
import { setContinuity } from '../../../store/slices/configSlice';
import { useGetUserContinuityQuery, useGetUserWatchingQuery } from '../../../store/services/user';
import { selectSiteBaseUrl } from '../../../store/selectors/configSelectors';
import { selectValidated } from '../../../store/selectors/authSelectors';
import {
  cleanLocalProgresses,
  getLocalProgresses,
  LocalStorageList,
} from '../../../store/localStorage/utils';
import * as ROUTES from '../../../routes/routes';
import Grid from '../../../components/Grid/Grid';
import DropdownMenu from '../../../components/DropdownMenu/DropdownMenu';
import DataStatusHandler from '../../../components/DataStatusHandler/DataStatusHandler';
import Button from '../../../components/Button/Button';
import { GridItem } from '../../../../types/item';

const ContinueWatching: FC = () => {
  const [filter, setFilter] = useState('');
  const [results, setResults] = useState<GridItem[]>([]);
  const [list, setList] = useState<GridItem[]>([]);
  const [page, setPage] = useState(DEFAULT_PAGE);
  const siteBaseUrl = useSelector(selectSiteBaseUrl);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isValidated = useSelector(selectValidated);

  const continuity = useGetUserContinuityQuery(
    { params: {} },
    {
      refetchOnMountOrArgChange: true,
      skip: isValidated === null,
    }
  );

  const { data, isLoading, isFetching, isError, refetch, isUninitialized } = useGetUserWatchingQuery(
    {
      params: {
        page,
        ...(filter && { filter: `type:${filter}` }),
      },
    },
    {
      refetchOnMountOrArgChange: true,
      skip: isValidated === null,
    }
  );

  const getList = (currentResults: GridItem[] = [], currentFilter = ''): GridItem[] => {
    const local = getLocalProgresses();

    if (currentFilter || !local.items?.length) {
      return currentResults;
    }

    const localUpdatedAt = local.updated_at || '';
    const dataUpdatedAt = data?.updated_at || '';

    if (localUpdatedAt < dataUpdatedAt) {
      return currentResults;
    }

    let result: GridItem[] = currentResults;

    // Update progress using localStorage if exists
    result = result.map((resultItem) => {
      const localItem = local.items.find((item) => item.id === resultItem.id);

      return {
        ...resultItem,
        progress: localItem?.progress || resultItem.progress,
      };
    });

    // Add item using localStorage if doesn't exists
    local.items.forEach((localItem) => {
      const isInResults = result.some((item) => item.id === localItem.id);

      if (!isInResults) result.unshift(localItem);
    });

    return uniqBy(result, 'id');
  };

  useEffect(() => {
    dispatch(setContinuity(continuity.data));
  }, [continuity.data]);

  const totalPages = useMemo(
    () => (data?.totalCount && data.pageSize ? Math.ceil(data.totalCount / data.pageSize) - 1 : 0),
    [data?.totalCount, data?.pageSize]
  );

  const handleScroll = useCallback(
    debounce((): void => {
      if (
        window.innerHeight + document.documentElement.scrollTop <=
          document.documentElement.offsetHeight - 100 ||
        isLoading ||
        totalPages <= page
      )
        return;

      setPage(page + 1);
    }, 300),
    [page, isLoading, totalPages]
  );

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  useEffect(() => {
    if (data && !filter) {
      setResults((prevSearchResults) => [...prevSearchResults, ...(data?.items || [])]);
    }

    if (data && filter) {
      setResults(getList(data.items, filter));
    }
  }, [data]);

  useEffect(() => {
    if (!isFetching && !isUninitialized) {
      setList(getList(results, filter));
    }
  }, [isFetching, isUninitialized, results, filter]);

  useEffect(() => {
    if (!isUninitialized) {
      refetch();
    }
  }, [filter, page, refetch]);

  useEffect(() => {
    cleanLocalProgresses(continuity.data as LocalStorageList);
  }, [continuity.data]);

  const onFilterSelection = (selectedOption): void => {
    setFilter(selectedOption.value);
    setPage(DEFAULT_PAGE);
  };

  return (
    <DataStatusHandler isLoading={isLoading} isError={isError}>
      <div className="MyProfile-continue-watching">
        <Flex className="MyProfile-content-filters" alignItems="center" justifyContent="center">
          <DropdownMenu
            btnLabel={t('content_filter_all_content')}
            options={contentFilters}
            onSelect={onFilterSelection}
          />
        </Flex>
        {!list.length ? (
          <Flex
            className="MyProfile-no-watched-items"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <h2 className="MyProfile-no-watched-items-title">{t('my_profile_no_videos_watched')}</h2>
            <Button
              className="MyProfile-no-watched-items-cta"
              variant="primary"
              text={t('go_to_home') || ''}
              onClick={() => navigate(`${siteBaseUrl}${ROUTES.HOME}`)}
            />
          </Flex>
        ) : (
          <Grid items={list as GridItem[]} columnCount={3} />
        )}
      </div>
    </DataStatusHandler>
  );
};

export default ContinueWatching;
