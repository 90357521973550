import { ColorHex, CountdownCircleTimer } from 'react-countdown-circle-timer';
import React, { FC, useCallback, useMemo } from 'react';
import classnames from 'classnames';
import { Flex } from '@brightcove/studio-components';

import './Countdown.scss';
import COLORS from '../../styles/_colors.module.scss';
import BREAKPOINTS from '../../styles/_breakpoints.module.scss';
import { useDimensions } from '../../hooks/useDimensions';

const minuteSeconds = 60;
const hourSeconds = minuteSeconds * 60;
const daySeconds = hourSeconds * 24;
const countdownColor = COLORS.cyan as ColorHex;

interface CountdownProps {
  time: string;
  className?: string;
  onComplete?: () => void;
}

const Countdown: FC<CountdownProps> = ({ time, className, onComplete }) => {
  const { width } = useDimensions();
  const now = new Date().getTime() / 1000;
  const endTime = new Date(time).getTime() / 1000;
  const initialRemainingTime = Math.max(0, endTime - now);

  const countdownUnits = useMemo(
    () => [
      { unit: 'day', value: (time) => Math.floor(time / daySeconds), duration: daySeconds },
      { unit: 'hour', value: (time) => Math.floor((time % daySeconds) / hourSeconds), duration: hourSeconds },
      {
        unit: 'min',
        value: (time) => Math.floor((time % hourSeconds) / minuteSeconds),
        duration: minuteSeconds,
      },
      { unit: 'sec', value: (time) => time % minuteSeconds, duration: minuteSeconds },
    ],
    []
  );

  const countdownProps = useCallback(() => {
    const tablet = parseInt(BREAKPOINTS.tablet, 10);
    const mobile = parseInt(BREAKPOINTS.mobile, 10);

    return width <= tablet
      ? width > mobile
        ? { size: 100, strokeWidth: 3.6 }
        : { size: 70, strokeWidth: 2.6 }
      : { size: 135, strokeWidth: 5 };
  }, [width]);

  return (
    <Flex className={classnames('Countdown-container', className)}>
      {countdownUnits.map(({ unit, value, duration }) => (
        <CountdownCircleTimer
          key={unit}
          isPlaying
          onComplete={onComplete}
          duration={duration}
          initialRemainingTime={initialRemainingTime}
          colors={countdownColor}
          {...countdownProps()}
        >
          {({ remainingTime }) => {
            const timeValue = value(remainingTime);

            return (
              <div>
                <div className="Countdown-value">{timeValue}</div>
                <div className="Countdown-text">{unit === 'day' && timeValue > 1 ? 'DAYS' : unit}</div>
              </div>
            );
          }}
        </CountdownCircleTimer>
      ))}
    </Flex>
  );
};

export default Countdown;
