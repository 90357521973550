import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { getFullLanguageCode, supportedLanguages, encodeURLParams, getLanguage } from '../../utils';
import config from '../../config';
import { CollectionDetail } from '../../../types/collection';

export const collectionApi = createApi({
  reducerPath: 'collection',
  baseQuery: fetchBaseQuery({
    baseUrl: config.api_url,
    prepareHeaders: (headers) => {
      const language = getLanguage();
      headers.set('user-language', getFullLanguageCode(language, supportedLanguages) as string);

      return headers;
    },
    paramsSerializer: encodeURLParams,
    credentials: 'include',
  }),
  endpoints: (builder) => ({
    getCollectionDetail: builder.query<CollectionDetail, string>({
      query: (id) => ({ url: `collection/${id}` }),
    }),
  }),
});

export const { useGetCollectionDetailQuery } = collectionApi;
