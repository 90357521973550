import _ from 'lodash';
import { configureStore } from '@reduxjs/toolkit';

import configReducer from './slices/configSlice';
import authReducer from './slices/authSlice';
import { userApi } from './services/user';
import { pageApi } from './services/page';
import { metadataApi } from './services/metadata';
import { collectionApi } from './services/collection';
import { authApi } from './services/auth';
import { maintenanceModeMiddleware } from './maintenanceModeMiddleware';

export const store = configureStore({
  reducer: {
    config: configReducer,
    auth: authReducer,
    [pageApi.reducerPath]: pageApi.reducer,
    [metadataApi.reducerPath]: metadataApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [collectionApi.reducerPath]: collectionApi.reducer,
  },
  // Adding the api middleware enables caching, invalidation, polling, and other useful features.
  middleware: (getDefaultMiddleware) =>
    _.concat(
      getDefaultMiddleware(),
      pageApi.middleware,
      metadataApi.middleware,
      userApi.middleware,
      authApi.middleware,
      collectionApi.middleware,
      maintenanceModeMiddleware
    ),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

// Inferred type: { config: ConfigState }
export type AppDispatch = typeof store.dispatch;
