import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import React, { FC, useEffect, useState } from 'react';
import classNames from 'classnames';
import { Flex } from '@brightcove/studio-components';

import { millisecondsToTimeString, getSimplifiedItemType } from '../../utils';
import { removeFavoriteLoadingId, setFavoriteLoadingId, setFavorites } from '../../store/slices/configSlice';
import { useAddUserFavoriteMutation } from '../../store/services/user';
import {
  selectContinuity,
  selectFavoriteLoadingId,
  selectFavorites,
  selectPreviewToken,
} from '../../store/selectors/configSelectors';
import { selectLogged } from '../../store/selectors/authSelectors';
import { updateLocalFavorite, getLocalFavorites, getLocalProgresses } from '../../store/localStorage/utils';
import { useGetPageUrl } from '../../hooks/useGetPageUrl';
import { getLabelForVideoType } from '../../helpers/utils';
import { SVGImage } from '../../assets/images';
import { TileItem } from '../../../types/item';
import { VideoType } from '../../../types/generic';

import './Tile.scss';

const Tile: FC<TileItem & { onFavoriteChange?: (removedId?: string) => void }> = (data) => {
  const { t } = useTranslation();
  const [tileInfo, setTileInfo] = useState<TileItem>(data || {});
  const pvtk = useSelector(selectPreviewToken);
  const favorites = useSelector(selectFavorites);
  const favoriteLoadingId = useSelector(selectFavoriteLoadingId);
  const continuity = useSelector(selectContinuity);
  const isLoggedIn = useSelector(selectLogged);
  const dispatch = useDispatch();
  const { id, name, type, image, duration, featured, productionYear, brightcoveVideoId } = tileInfo;
  const url = useGetPageUrl(data);
  const contentType = getSimplifiedItemType(type);
  const [addUserFavorite] = useAddUserFavoriteMutation();

  const continuityItem = continuity?.items?.find((item) => item.id === id);
  const isBlocked = !!favoriteLoadingId;
  const isLoading = id && favoriteLoadingId === id;

  const isFavorite = (): boolean => {
    const local = getLocalFavorites().items.find((item) => item.id === id);

    if (local?.removed) return false;

    return !!local?.id || continuityItem?.favorite || favorites?.some((favId) => favId === id);
  };

  const getProgress = (): number => {
    const local = getLocalProgresses().items.find((item) => item.brightcoveVideoId === brightcoveVideoId);

    return local?.progress || continuityItem?.progress || 0;
  };

  const hasProgress = (): boolean => getProgress() > 0;

  useEffect(() => {
    setTileInfo(data);
  }, [data]);

  const addFavorite = (): void => {
    const items = [...favorites, id];
    updateLocalFavorite(tileInfo);
    dispatch(setFavorites(items));
  };

  const removeFavorite = (): void => {
    const items = favorites.filter((favId) => favId !== id);
    updateLocalFavorite(tileInfo, true);
    dispatch(setFavorites(items));
  };

  const onFavoriteClick = async (e): Promise<void> => {
    e.preventDefault();
    e.stopPropagation();

    if (!isLoading && !isBlocked) {
      dispatch(setFavoriteLoadingId(id));

      const action = isFavorite() ? 'remove' : 'add';

      if (isFavorite()) {
        removeFavorite();
      } else {
        addFavorite();
      }

      const res = await addUserFavorite({ id, action });
      dispatch(removeFavoriteLoadingId());

      if (!('error' in res)) {
        if (action === 'remove') {
          data?.onFavoriteChange?.(id);
        }
      }
    }
  };

  return url ? (
    <Link key={id} to={url} className={classNames('Tile', contentType)}>
      <Flex className="Tile-wrapper" flexDirection="column">
        <div className="Tile-image-container">
          <div
            className="Tile-image"
            style={{ backgroundImage: `url(${image || SVGImage.DefaultThumbnail})` }}
          >
            {featured && <p className="Tile-featured-label">{t('featured')}</p>}

            {/* Progress bar */}
            {hasProgress() && duration && !pvtk && (
              <div className="Tile-progress">
                <div
                  className="Tile-progress-bar"
                  style={{ width: `${(getProgress() / duration) * 100}%` }}
                />
              </div>
            )}

            {/* Favorite button */}
            {!pvtk && isLoggedIn ? (
              isFavorite() ? (
                <div
                  className={classNames('Tile-favorite', { loading: isLoading })}
                  onClick={onFavoriteClick}
                >
                  <img src={SVGImage.FavoriteIcon} alt="favorite" />
                </div>
              ) : (
                <div
                  className={classNames('Tile-favorite', { loading: isLoading })}
                  onClick={onFavoriteClick}
                >
                  <img src={SVGImage.FavoriteOffIcon} alt="favorite off" />
                </div>
              )
            ) : null}

            <div className="Tile-image-overlay-gradient"></div>
          </div>
        </div>

        <Flex className="Tile-info" flexDirection="column">
          <h4 className="Tile-info-title">{name}</h4>
          <Flex className="Tile-info-metadata" alignItems="center">
            {productionYear && <span>{productionYear}</span>}
            {duration && <span>{millisecondsToTimeString(duration)}</span>}
            {!!duration && <span>{getLabelForVideoType(contentType as VideoType)}</span>}
          </Flex>
        </Flex>
      </Flex>
    </Link>
  ) : null;
};

export default Tile;
