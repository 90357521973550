import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import React, { FC, useEffect } from 'react';

import { setLogin } from '../../store/slices/authSlice';
import * as ROUTES from '../../routes/routes';
import useValidate from '../../hooks/useValidate';
import config from '../../config';
import Loading from '../../components/Loading/Loading';

import './AuthCallback.scss';

const AuthCallback: FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { validateUser } = useValidate();
  const searchParams = new URLSearchParams(location.search);
  const code = searchParams.get('code');
  const errorOIDC = searchParams.get('error');
  const state = searchParams.get('state');

  const validateCallback = async (): Promise<void> => {
    await validateUser(code, state, true);
  };

  useEffect(() => {
    if (errorOIDC) {
      forceLogout();
    } else if (code && state) {
      validateCallback();
    }
  }, [code, state, errorOIDC]);

  const forceLogout = (): void => {
    const destination = `${config.api_url}/auth${ROUTES.LOGOUT}?redirectUri=${window.location.origin}`;
    dispatch(setLogin(false));
    window.location.href = destination;
  };

  return (
    <div className="AuthCallback-container">
      <Loading />
    </div>
  );
};

export default AuthCallback;
