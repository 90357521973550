import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { debounce, uniqBy } from 'lodash';
import { Flex } from '@brightcove/studio-components';

import { contentFilters } from '../MyProfile';
import { DEFAULT_PAGE } from '../../../utils';
import { useGetUserFavoritesQuery } from '../../../store/services/user';
import { selectSiteBaseUrl } from '../../../store/selectors/configSelectors';
import { selectValidated } from '../../../store/selectors/authSelectors';
import { cleanLocalFavorites, getLocalFavorites } from '../../../store/localStorage/utils';
import * as ROUTES from '../../../routes/routes';
import Grid from '../../../components/Grid/Grid';
import DropdownMenu from '../../../components/DropdownMenu/DropdownMenu';
import DataStatusHandler from '../../../components/DataStatusHandler/DataStatusHandler';
import Button from '../../../components/Button/Button';
import { GridItem } from '../../../../types/item';

const Favorites: FC = () => {
  const [filter, setFilter] = useState('');
  const [results, setResults] = useState<GridItem[]>([]);
  const [list, setList] = useState<GridItem[]>([]);
  const [page, setPage] = useState(DEFAULT_PAGE);
  const siteBaseUrl = useSelector(selectSiteBaseUrl);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isValidated = useSelector(selectValidated);

  const { data, isFetching, isError, refetch, isUninitialized } = useGetUserFavoritesQuery(
    {
      params: {
        page,
        ...(filter && { filter: `type:${filter}` }),
      },
    },
    {
      refetchOnMountOrArgChange: true,
      skip: isValidated === null,
    }
  );

  const totalPages = useMemo(
    () => (data?.totalCount && data.pageSize ? Math.ceil(data.totalCount / data.pageSize) - 1 : 0),
    [data?.totalCount, data?.pageSize]
  );

  const handleScroll = useCallback(
    debounce((): void => {
      if (
        window.innerHeight + document.documentElement.scrollTop <=
          document.documentElement.offsetHeight - 100 ||
        isFetching ||
        totalPages <= page
      )
        return;

      setPage(page + 1);
    }, 300),
    [page, isFetching, totalPages]
  );

  const getList = (currentResults: GridItem[] = [], currentFilter = ''): GridItem[] => {
    const local = getLocalFavorites();

    if (currentFilter || !local.items?.length) return uniqBy(currentResults, 'id');

    const localUpdatedAt = local.updated_at || '';
    const dataUpdatedAt = data?.updated_at || '';
    let result = currentResults;

    if (localUpdatedAt > dataUpdatedAt) {
      const removedItems = local.items.filter((item) => item.removed).map((item) => item.id) || [];
      const addedItems = local.items.filter((item) => !item.removed) || [];
      result = results.filter((item) => !removedItems.some((removedId) => removedId === item.id));

      addedItems.forEach((item) => {
        const existingItem = result.find((resultItem) => resultItem.id === item.id);

        if (!existingItem) result.unshift(item);
      });

      result = uniqBy(result, 'id');
    }

    return result;
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  useEffect(() => {
    if (data && !filter) {
      setResults((prevSearchResults) => [...prevSearchResults, ...(data?.items || [])]);
    }

    if (data && filter) {
      setResults(data.items);
    }
  }, [data]);

  useEffect(() => {
    cleanLocalFavorites(data);
  }, [data?.updated_at]);

  useEffect(() => {
    if (!isUninitialized) {
      refetch();
    }
  }, [filter, page, refetch, isUninitialized]);

  useEffect(() => {
    if (!isFetching && !isUninitialized) {
      setList(getList(results, filter));
    }
  }, [isFetching, isUninitialized, results, filter]);

  const onFilterSelection = (selectedOption): void => {
    setFilter(selectedOption.value);
    setPage(DEFAULT_PAGE);
  };

  const handleFavoriteChange = (removedId?: string): void => {
    if (removedId) {
      setTimeout(() => {
        setResults((current) => current.filter((item) => item.id !== removedId));
        refetch();
      }, 4000);
    }
  };

  return (
    <div className="MyProfile-favorites">
      <Flex className="MyProfile-content-filters" alignItems="center" justifyContent="center">
        <DropdownMenu
          btnLabel={t('content_filter_all_content')}
          options={contentFilters}
          onSelect={onFilterSelection}
        />
      </Flex>

      <DataStatusHandler isLoading={isUninitialized || isFetching} isError={isError}>
        {!list.length ? (
          <Flex
            className="MyProfile-no-favorites"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <h2 className="MyProfile-no-favorites-title">{t('my_profile_no_favorites')}</h2>
            <p className="MyProfile-no-favorites-info">{t('my_profile_no_favorites_info')}</p>
            <Button
              className="MyProfile-no-favorites-cta"
              variant="primary"
              text={t('go_to_home') || ''}
              onClick={() => {
                const url = `${siteBaseUrl}${ROUTES.HOME}`;
                navigate(url);
              }}
            />
          </Flex>
        ) : (
          <Grid items={list} columnCount={3} onFavoriteChange={handleFavoriteChange} />
        )}
      </DataStatusHandler>
    </div>
  );
};

export default Favorites;
