import { VideoType } from '../../types/generic';

const videoTypeLabel: Record<VideoType, string> = {
  episode: 'Episode',
  ad: 'Ad',
  trailer: 'Trailer',
  movie: 'Movie',
};

export const getLabelForVideoType = (type: VideoType): string => videoTypeLabel[type] || type;
