import { useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

import { setPreviewToken } from '../store/slices/configSlice';

export const usePreviewToken = (): void => {
  const previewToken = 'pvtk';
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const pvtk = searchParams?.get(previewToken);

  useEffect(() => {
    if (pvtk) {
      dispatch(setPreviewToken(pvtk));
    }
  }, [dispatch, pvtk]);
};
