import React, { FC } from 'react';
import classnames from 'classnames';
import './Loading.scss';

interface LoadingProps {
  className?: string;
}

const Loading: FC<LoadingProps> = ({ className }) => {
  const loadingClassName = classnames('Loading-indicator', className);

  return (
    <div className={loadingClassName}>
      <svg className="circular">
        <circle className="path" cx="50" cy="50" r="20" fill="none" strokeWidth="4" strokeMiterlimit="10" />
      </svg>
    </div>
  );
};

export default Loading;
