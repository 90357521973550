import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import debounce from 'lodash.debounce';

import ErrorPage from '../ErrorPage/ErrorPage';
import { DEFAULT_PAGE } from '../../utils';
import { useGetSectionDetailPageQuery } from '../../store/services/page';
import { selectPreviewToken } from '../../store/selectors/configSelectors';
import { selectValidated } from '../../store/selectors/authSelectors';
import PageHead from '../../components/PageHead/PageHead';
import Loading from '../../components/Loading/Loading';
import Grid from '../../components/Grid/Grid';
import { GridItem } from '../../../types/item';

type UrlParams = { pageId: string; sectionId: string };

const SectionDetail: FC = () => {
  const pvtk = useSelector(selectPreviewToken);
  const { pageId, sectionId } = useParams<UrlParams>();
  const [page, setPage] = useState(DEFAULT_PAGE);
  const [results, setResults] = useState<GridItem[]>([]);
  const isValidated = useSelector(selectValidated);

  const { data, isLoading, isError } = useGetSectionDetailPageQuery(
    {
      pageId: pageId as string,
      sectionId: sectionId as string,
      params: {
        page,
        ...(pvtk && { pvtk }),
      },
    },
    {
      refetchOnMountOrArgChange: true,
      skip: isValidated === null,
    }
  );

  const totalPages = useMemo(
    () => (data?.totalCount && data?.pageSize ? Math.ceil(data.totalCount / data.pageSize) - 1 : 0),
    [data?.totalCount, data?.pageSize]
  );

  const handleScroll = useCallback(
    debounce((): void => {
      if (
        window.innerHeight + document.documentElement.scrollTop <=
          document.documentElement.offsetHeight - 100 ||
        isLoading ||
        totalPages <= page
      )
        return;

      setPage(page + 1);
    }, 300),
    [page, isLoading, totalPages]
  );

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  useEffect(() => {
    if (data) {
      setResults((prevSearchResults) => [...prevSearchResults, ...(data?.items || [])]);
    }
  }, [data]);

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <ErrorPage />;
  }

  return (
    <div>
      <PageHead title={data?.name} />
      <div className="main-content p-5">
        <Grid items={results} columnCount={3} gridName={data?.name} />
        {isLoading && <Loading />}
      </div>
    </div>
  );
};

export default SectionDetail;
