import moment from 'moment';

import { CTA } from '../../types/generic';

export const DEFAULT_PAGE = 1;

export const DEFAULT_PAGE_SIZE = 48;

export type SupportedLanguagesCodes =
  | 'en-us'
  | 'ja-jp'
  | 'ko-kr'
  | 'zh-hans'
  | 'zh-hant'
  | 'de-de'
  | 'es-es'
  | 'fr-fr';

export type SupporrtedLanguagesLabels =
  | 'English'
  | '日本語'
  | '한국어'
  | '简体中文'
  | '繁體中文'
  | 'Deutsch'
  | 'Español'
  | 'Français';

export interface SupportedLanguage {
  label: SupporrtedLanguagesLabels;
  value: SupportedLanguagesCodes;
}

type SupportedLanguageShape = {
  short: string;
  long: SupportedLanguagesCodes;
};

export const supportedLanguages: SupportedLanguage[] = [
  {
    label: 'English',
    value: 'en-us',
  },
  {
    label: '日本語',
    value: 'ja-jp',
  },
  {
    label: '한국어',
    value: 'ko-kr',
  },
  {
    label: '简体中文',
    value: 'zh-hans',
  },
  {
    label: '繁體中文',
    value: 'zh-hant',
  },
  {
    label: 'Deutsch',
    value: 'de-de',
  },
  {
    label: 'Español',
    value: 'es-es',
  },
  {
    label: 'Français',
    value: 'fr-fr',
  },
];

const languageExceptions = new Set(['zh-hans', 'zh-hant']);

export const getLanguageCode = (language: string): string =>
  !languageExceptions.has(language) ? language.split('-')[0] : language;

export const supportedLanguagesCodes: SupportedLanguageShape[] = supportedLanguages.map((lang) => ({
  short: getLanguageCode(lang.value),
  long: lang.value,
}));

export const isSameLanguage = (currentLang: string, newLangCode: string): boolean => {
  if (!currentLang || !newLangCode) return false;

  const newLanguageCodes = supportedLanguagesCodes.find(
    (item) => item.long === newLangCode || item.short === newLangCode
  );

  return newLanguageCodes?.long === currentLang || newLanguageCodes?.short === currentLang;
};

export const isLanguageChangeBlocked = (): boolean => {
  const langBlockedUntil = localStorage.getItem('langBlockedUntil');

  if (!langBlockedUntil) return false;

  return langBlockedUntil >= moment().toISOString();
};

export const removeLanguangeBlocker = (): void => {
  localStorage.removeItem('langBlockedUntil');
};

export const itemTypes = [
  'SeriesContent',
  'SeasonContent',
  'EpisodeContent',
  'MovieContent',
  'AdContent',
  'TrailerContent',
  'StandardEvent',
  'CustomPage',
  'StandardPage',
];

export const getLanguageFromUrl = (): string | null => {
  const pathName = window.location.pathname;
  const pathArr = pathName.split('/');
  const languageInPath = pathArr.length > 1 ? pathArr[1].toLowerCase() : '';
  const valueIsLanguage = supportedLanguagesCodes.some(
    (item) => item.long === languageInPath || item.short === languageInPath
  );

  return valueIsLanguage ? languageInPath : null;
};

export const getLanguageRoute = (newLanguage: string): string | null => {
  const pathName = window.location.pathname;
  const languageInPath = getLanguageFromUrl();
  const languages = supportedLanguages.map((lang) => getLanguageCode(lang.value));
  const langCode = getLanguageCode(newLanguage);

  if (languageInPath !== langCode) {
    let route = `/${langCode}${pathName}`;

    if (languageInPath && languages.includes(languageInPath)) {
      route = pathName.replace(languageInPath, langCode);
    }

    if (langCode === 'en') {
      route = route.replace(`/${langCode}`, '');
    }

    return route;
  }

  return null;
};

export const getSiteLanguage = (): string => {
  const savedLanguage = localStorage.getItem('lang');
  const languageParam = location?.pathname.split('/').filter((param) => param !== '')[0];
  const language =
    languageParam && languageParam !== 'language' ? languageParam : savedLanguage ? savedLanguage : 'en';

  if (supportedLanguages.some((l) => getLanguageCode(l.value) === language)) {
    return language;
  }

  return 'en-us';
};

export const millisecondsToTimeString = (durationInMs: number): string => {
  const durationInSeconds = durationInMs / 1000;
  const hours = Math.floor(durationInSeconds / 3600);
  const minutes = Math.floor((durationInSeconds % 3600) / 60);
  const seconds = Math.floor(durationInSeconds - hours * 3600 - minutes * 60);
  let duration = '';

  if (hours > 0) {
    duration += `${hours}h `;
  }

  if (minutes > 0) {
    duration += `${minutes}m `;
  }

  if (seconds > 0) {
    duration += `${seconds}s`;
  }

  return duration;
};

export const timeStringToMilliseconds = (timeString: string): number => {
  // Regular expression to match the time string
  const regex = /^((\d+)h)?((\d+)m)?((\d+)s)?$/;
  const match = timeString.match(regex);

  if (!match) return 0;

  const hours = parseInt(match[2]) || 0;
  const minutes = parseInt(match[4]) || 0;
  const seconds = parseInt(match[6]) || 0;

  return (hours * 3600 + minutes * 60 + seconds) * 1000;
};

export const getSimplifiedItemType = (type?: string): string => {
  let contentType = type?.toLowerCase();

  if (type && itemTypes.includes(type)) {
    contentType = contentType?.replace(/standard|custom|content/g, '');
  }

  return contentType ?? '';
};

export const PRE_EVENT = 'preEvent';
export const POST_EVENT = 'postEvent';
export const LIVE_EVENT = 'liveEvent';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const getTypeOfEvent = (startDate: string | null, endDate: string) => {
  if (!startDate) return POST_EVENT;

  const currentDate = moment();
  const initDate = moment(new Date(startDate));
  const finalDate = moment(new Date(endDate));

  if (currentDate.isBetween(initDate, finalDate)) {
    return LIVE_EVENT;
  } else if (currentDate.isBefore(initDate)) {
    return PRE_EVENT;
  } else if (currentDate.isAfter(finalDate)) {
    return POST_EVENT;
  } else {
    return POST_EVENT;
  }
};

export const generateUniqueId = (): string => {
  const timestamp = new Date().getTime().toString(16);
  const randomPart = Math.random().toString(16).substr(2, 8);

  return `${timestamp}-${randomPart}`;
};

export const getUrlPath = (url: string): string => {
  try {
    const urlObj = new URL(url);

    return urlObj.pathname;
  } catch (error) {
    return '';
  }
};

export const isSameDomain = (url: string): boolean => url.includes(window.location.origin);

export const getFullLanguageCode = (
  shortLangCode?: string | null,
  supportedLanguages?: SupportedLanguage[]
): SupportedLanguagesCodes | null => {
  if (!shortLangCode || !supportedLanguages) return null;

  const foundLanguage = supportedLanguages.find((lang) => lang.value.includes(shortLangCode));

  return foundLanguage ? foundLanguage.value : 'en-us';
};

export const getStringBetweenStrings = (startStr: string, endStr: string, str: string): string => {
  const pos = str.indexOf(startStr) + startStr.length;

  return str.substring(pos, str.indexOf(endStr, pos));
};

export const isValidCta = (cta: CTA): boolean =>
  cta && !!cta.url?.length && (!!cta.label?.length || !!cta.text?.length);

export const ON_CUEPOINT_SELECTED = 'onCuepointSelected';

export const encodeURLParams = (params: Record<string, string>): string => {
  const result = {};
  Object.entries(params).forEach(([key, value]) => {
    result[key] = encodeURIComponent(value);
  });

  return new URLSearchParams(result).toString();
};

const getValidBrowserLanguage = (): string | null => {
  const browserLangCode = getLanguageCode(navigator.language.toLowerCase());
  const isValidLang = supportedLanguages.some(({ value }) => getLanguageCode(value) === browserLangCode);

  return isValidLang ? browserLangCode : null;
};

export const getLanguage = (): string => {
  const savedLanguage = localStorage.getItem('lang');
  const languageParam = window.location?.pathname.split('/').filter((param) => param !== '')[0];
  const languageParamIsValid = supportedLanguages.some((l) => getLanguageCode(l.value) === languageParam);

  if (languageParam && languageParamIsValid) {
    return languageParam;
  }

  if (!savedLanguage) {
    return getValidBrowserLanguage() || 'en';
  }

  return savedLanguage;
};
