import { Link } from 'react-router-dom';
import React, { FC, Fragment } from 'react';
import './SettingsList.scss';
import classnames from 'classnames';

import { generateUniqueId } from '../../../utils';

interface SettingListProps {
  column: Array<{
    title: string;
    items: Array<{ text: string; path?: string; subHeader?: string; isEmail?: boolean }>;
  }>;
  className: string;
}

const SettingsList: FC<SettingListProps> = ({ column, className }) => (
  <div className={`SettingsList ${className}`}>
    {column.map(({ title, items }) => (
      <Fragment key={generateUniqueId()}>
        <div className="SettingsList-title">{title}</div>

        {items.map(({ text, path, subHeader, isEmail }) => {
          const itemClass = classnames('SettingsList-item', { 'SettingsList-item-green': isEmail });

          return (
            <Fragment key={generateUniqueId()}>
              {subHeader && <div className="SettingsList-subheader">{subHeader}</div>}
              {path ? (
                <Link to={path} className={itemClass}>
                  {text}
                </Link>
              ) : (
                <div className={itemClass}>{text}</div>
              )}
            </Fragment>
          );
        })}
      </Fragment>
    ))}
  </div>
);

export default SettingsList;
