import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import React, { FC, useMemo } from 'react';
import InnerHTML from 'dangerously-set-html-content';

import { generateUniqueId } from '../../utils';
import {
  selectMaintenanceMode,
  selectSiteBaseUrl,
  selectSiteConfig,
} from '../../store/selectors/configSelectors';
import { validateHTML } from '../../helpers/validators';
import { SVGImage } from '../../assets/images';

import './Footer.scss';

const Footer: FC = () => {
  const { t } = useTranslation();
  const siteConfig = useSelector(selectSiteConfig);
  const footerCustomHtml = siteConfig?.footer?.templateRef;
  const footerLinks = siteConfig?.footer?.data;
  const siteBaseUrl = useSelector(selectSiteBaseUrl);
  const maintenanceMode = useSelector(selectMaintenanceMode);
  const customLinksMapped = useMemo(
    () =>
      footerLinks?.length
        ? footerLinks?.map(({ id, title, urlSafeName }) => ({
            path: `${siteBaseUrl}/page/${id}${urlSafeName ? `/${urlSafeName}` : ''}`,
            text: title,
          }))
        : [],
    [footerLinks, siteBaseUrl]
  );

  const htmlSnippet = validateHTML(siteConfig?.footerHtml);
  const year = new Date().getFullYear();

  const handleCookieSettingsClick = (): void => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any)?.OneTrust?.ToggleInfoDisplay?.();
  };

  return (
    <div className="Footer">
      {/* InnerHTML will execute the html if it's a script */}
      {!!htmlSnippet && <InnerHTML html={htmlSnippet} />}
      {footerCustomHtml && <div dangerouslySetInnerHTML={{ __html: footerCustomHtml }} />}
      {siteConfig && !footerCustomHtml && !maintenanceMode && (
        <>
          <NavLink to="/">
            <img className="Footer-logo" src={SVGImage.NetAppLogo} alt="NetApp Logo" />
          </NavLink>
          {customLinksMapped.map(({ path, text }) => (
            <NavLink key={generateUniqueId()} to={path} className="Footer-link">
              {text}
            </NavLink>
          ))}

          <a target="_self" className="Footer-link ot-sdk-show-settings" onClick={handleCookieSettingsClick}>
            {t('cookie_settings')}
          </a>

          <div className="Footer-right-text">{t('copyright', { year })}</div>
        </>
      )}
      {maintenanceMode && (
        <>
          <NavLink to="/">
            <img className="Footer-logo" src={SVGImage.NetAppLogo} alt="NetApp Logo" />
          </NavLink>
          <div className="Footer-right-text">{t('copyright', { year })}</div>
        </>
      )}
    </div>
  );
};

export default Footer;
