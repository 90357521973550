import React, { FC } from 'react';
import './SectionTitle.scss';

interface SectionTitleProps {
  children: React.ReactNode;
}

const SectionTitle: FC<SectionTitleProps> = ({ children }) => <div className="SectionTitle">{children}</div>;

export default SectionTitle;
