import { useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { Flex } from '@brightcove/studio-components';
import ReactPlayerLoader from '@brightcove/react-player-loader';

import { useInteraction } from '../InteractionContext/InteractionContenxt';
import {
  ON_CUEPOINT_SELECTED,
  getFullLanguageCode,
  supportedLanguages,
  timeStringToMilliseconds,
} from '../../utils';
import { selectPreviewToken, selectSiteLanguage } from '../../store/selectors/configSelectors';
import { selectUser } from '../../store/selectors/authSelectors';
import config from '../../config';
import { PlayerSection, Section } from '../../../types/section';
import { TileItem } from '../../../types/item';

import './ReactPlayer.scss';

interface Options {
  autoplay?: boolean;
  loop?: boolean;
  controls?: boolean;
}

interface ReactPlayerProps {
  muted?: boolean;
  section: PlayerSection;
  sections?: Section[];
  progress?: number;
  options?: Options;
}

const ReactPlayer: FC<ReactPlayerProps> = ({ muted, section, sections, options = {} }) => {
  const { video, eventId, allowPlayback, allowInteraction } = section;
  const location = useLocation();
  const { id } = useParams();
  const siteLanguage = useSelector(selectSiteLanguage);
  const pvtk = useSelector(selectPreviewToken);
  const user = useSelector(selectUser);
  // const continuity = useSelector(selectContinuity);
  const { interactionState } = useInteraction();
  // had to type is as any because there are no types for BC player
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [playerRef, setPlayerRef] = useState<any>(null);
  const searchParams = new URLSearchParams(location.search);
  const isVideoDetailPage = useMemo(() => location.pathname.includes('video-detail'), [location]);

  // const continuityItem = useMemo(
  //   () => continuity?.items?.find((item) => item.id === id),
  //   [continuity?.items]
  // );

  // const getProgress = (): number => {
  //   const local = getLocalProgresses().items.find((item) => item.brightcoveVideoId === video);

  //   return local?.progress || continuityItem?.progress || 0;
  // };

  const getProgress = (): number => {
    const timeParamInUrl = searchParams?.get('t');

    if (!timeParamInUrl) return 0;

    const timeInMs = timeStringToMilliseconds(timeParamInUrl);

    const video = sections?.find((item) => item.type === 'VideoDetail') as TileItem;

    if (video?.duration && video.duration < timeInMs) return 0;

    return timeInMs;
  };

  // if sign in comes back, use the following function
  // const buildLocalProgress = (currentProgress: number): ProgressItem | undefined => {
  //   if (pageId && video && !!sections.length) {
  //     const result = sections?.find(
  //       (item) => item.type === 'VideoDetail' && item.pageId === pageId
  //     ) as TileItem;

  //     return {
  //       id: pageId,
  //       name: result.name,
  //       type: 'MovieContent',
  //       image: result.image,
  //       genre: result.genre,
  //       duration: result.duration,
  //       featured: result.featured,
  //       productionYear: result.productionYear,
  //       brightcoveVideoId: video,
  //       progress: currentProgress,
  //     } as TileItem;
  //   }
  // };

  const isLive = (player): boolean => {
    const duration = player?.duration();

    return duration === Infinity || duration === -1;
  };

  // if sign in comes back, use the following function
  // const updateProgress = useCallback(
  //   async (currentProgress: number): Promise<void> => {
  //     if (user?.id) {
  //       const postData = {
  //         user_id: user?.id,
  //         video_id: video,
  //         progress: currentProgress,
  //       };
  //       updateLocalProgress(buildLocalProgress(currentProgress));
  //       try {
  //         await fetch(config.update_progress_url as string, {
  //           method: 'POST',
  //           headers: { 'Content-Type': 'application/json' },
  //           body: JSON.stringify(postData),
  //         });
  //       } catch (error) {
  //         // eslint-disable-next-line no-console
  //         console.log(error);
  //       }
  //     }
  //   },
  //   [user, user?.id]
  // );

  useEffect(() => () => playerRef?.dispose(), []);

  useEffect(() => {
    const progress = getProgress();
    const time = progress / 1000;

    if (playerRef && isVideoDetailPage && time > 1) {
      playerRef?.currentTime?.(time - 1);
    }
  }, [playerRef]);

  const onSuccess = (success): void => {
    if (!pvtk) {
      let interval;
      const player = success.ref;
      setPlayerRef(player);

      if (!isLive(player) && isVideoDetailPage) {
        player.on('play', async () => {
          interval = setInterval(async () => {
            try {
              // if sign in comes back, replace with the following:
              // await updateProgress(player?.currentTime() * 1000);
            } catch (error) {
              clearInterval(interval);
            }
          }, 5000);
        });
        player.on(['pause', 'ended'], async () => {
          // if sign in comes back, replace with the following:
          // await updateProgress(player?.currentTime() * 1000);
          clearInterval(interval);
        });
      }
    }
  };

  useEffect(() => {
    if (interactionState) {
      const selectedTime = interactionState[ON_CUEPOINT_SELECTED];
      playerRef?.currentTime(selectedTime);
      playerRef?.play();
    }
  }, [interactionState]);

  useEffect(() => {
    if (typeof muted === 'boolean') {
      playerRef?.muted(muted);
    }
  }, [muted, playerRef]);

  return (
    <Flex justifyContent="center" className={`ReactPlayer-container`}>
      <ReactPlayerLoader
        attrs={{
          className: `ReactPlayer ${options.controls === false ? 'hide-controls' : ''}`,
        }}
        accountId={config.account_id}
        videoId={video}
        playerId={config.player_id}
        options={options}
        embedOptions={{
          responsive: true,
        }}
        onEmbedCreated={(embed) => {
          const appLang = getFullLanguageCode(siteLanguage, supportedLanguages);

          if (eventId) {
            embed.setAttribute('data-event-id', eventId);
          }

          if (isVideoDetailPage && id) {
            embed.setAttribute('data-content-id', id);
          }

          allowPlayback && embed.setAttribute('data-playback-allowed', allowPlayback);
          allowInteraction && embed.setAttribute('data-interactivity-allowed', allowInteraction);
          user?.extId && embed.setAttribute('data-user-ext-id', user?.extId);
          user?.id && embed.setAttribute('data-user-id', user?.id);
          embed.setAttribute('data-app-lang', appLang);
        }}
        onSuccess={onSuccess}
        onFailure={(e) => {
          // eslint-disable-next-line no-console
          console.log('Player Error: ', e);
        }}
        inactivityTimeout={3000}
      />
    </Flex>
  );
};

export default ReactPlayer;
