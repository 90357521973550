import moment from 'moment';

import { FavoriteItem, ProgressItem, SiteConfig } from '../slices/configSlice';
import { getFullLanguageCode, supportedLanguages } from '../../utils';
import { TileItem } from '../../../types/item';

export type LocalStorageKey = 'favorites' | 'progresses' | 'siteConfig' | 'lang';

export interface LocalStorageList<T = never> {
  updated_at?: string;
  items: T[];
}

export interface LocalStorageRecord<T = never> {
  updated_at?: string;
  data?: T;
}

const defaultList: LocalStorageList = {
  updated_at: '',
  items: [],
};

const defaultRecord: LocalStorageRecord = {
  updated_at: '',
  data: undefined,
};

// value in minutes that serves as an interval to remove localStorage lists
const CLEAN_INTERVAL_TTL = 10;
const LOCAL_CONFIG_TTL = 5;

const getLocalList = <T>(key: LocalStorageKey): LocalStorageList<T> => {
  const result = localStorage.getItem(key);

  if (result) return JSON.parse(result);

  return defaultList;
};

const setLocalList = <T>(key: LocalStorageKey, params: LocalStorageList<T>): void =>
  localStorage.setItem(key, JSON.stringify(params));

const removeLocalItem = (key: LocalStorageKey): void => localStorage.removeItem(key);

const cleanLocalList = (key: LocalStorageKey, updated_at = ''): void => {
  const currentUpdatedAt = getLocalList(key).updated_at || '';
  const cleanLimit = moment().subtract(CLEAN_INTERVAL_TTL, 'minutes');
  const isVeryOld = moment(currentUpdatedAt).isBefore(cleanLimit);

  if (currentUpdatedAt < updated_at || isVeryOld) {
    localStorage.setItem(key, JSON.stringify(defaultList));
  }
};

const getLocalRecord = <T>(key: LocalStorageKey): LocalStorageRecord<T> => {
  const result = localStorage.getItem(key);

  if (result) return JSON.parse(result);

  return defaultRecord;
};

const setLocalRecord = <T>(key: LocalStorageKey, params: LocalStorageRecord<T>): void =>
  localStorage.setItem(key, JSON.stringify(params));

// -- Local favorites API

export const getLocalFavorites = (): LocalStorageList<FavoriteItem> =>
  getLocalList<FavoriteItem>('favorites');

export const cleanLocalFavorites = (params?: LocalStorageList<FavoriteItem>): void =>
  cleanLocalList('favorites', params?.updated_at);

export const removeLocalFavorites = (): void => removeLocalItem('favorites');

export const updateLocalFavorite = (video: TileItem, remove = false): void => {
  const updated_at = moment().toISOString();
  const items = getLocalFavorites().items;
  const existingItem = items.find((item) => item.id === video.id);

  if (existingItem) {
    existingItem.removed = remove;
  } else {
    items.unshift({ ...video, removed: remove });
  }

  setLocalList('favorites', { items, updated_at });
};

// -- Local progresses API

export const getLocalProgresses = (): LocalStorageList<ProgressItem> => getLocalList('progresses');

export const cleanLocalProgresses = (params?: LocalStorageList<ProgressItem>): void =>
  cleanLocalList('progresses', params?.updated_at);

export const removeLocalProgresses = (): void => removeLocalItem('progresses');

export const updateLocalProgress = (video?: ProgressItem): void => {
  if (video) {
    const updated_at = moment().toISOString();
    const items = getLocalProgresses().items;
    const existingItem = items.find((item) => item.id === video.id);

    if (existingItem) {
      existingItem.progress = video.progress;
    } else {
      items.unshift(video);
    }

    setLocalList('progresses', { items, updated_at });
  }
};

// -- Local config API

export const getLocalConfig = (): LocalStorageRecord<SiteConfig> => getLocalRecord('siteConfig');

export const getCurrentLocalConfig = (languageParam?: string): SiteConfig | undefined => {
  const lang = localStorage.getItem('lang') || undefined;
  const longLanguage = getFullLanguageCode(lang, supportedLanguages);

  if (!languageParam && !longLanguage) return;

  const data = getLocalConfig().data;

  if (data?.language === longLanguage || data?.language === languageParam) {
    return data;
  }

  return;
};

export const cleanLocalConfig = (params?: LocalStorageList<SiteConfig>): void =>
  cleanLocalList('siteConfig', params?.updated_at);

export const removeLocalConfig = (): void => removeLocalItem('siteConfig');

export const updateLocalConfig = (config?: SiteConfig): void => {
  if (config) {
    const updated_at = moment().toISOString();

    const data = config || getCurrentLocalConfig();

    setLocalRecord('siteConfig', { data, updated_at });
  }
};

export const isLocalConfigExpired = (): boolean => {
  const lastUpdatedAt = getLocalConfig().updated_at;

  if (!lastUpdatedAt) return true;

  const lastConfigUpdate = moment(lastUpdatedAt);
  const localConfigTtl = moment().subtract(LOCAL_CONFIG_TTL, 'minutes');

  return lastConfigUpdate.isBefore(localConfigTtl);
};
