import React, { CSSProperties, FC } from 'react';
import classnames from 'classnames';
import './Carousel.scss';

interface ArrowProps {
  className?: string;
  style?: CSSProperties;
  onClick?: () => void;
  type: 'prev' | 'next';
  children: JSX.Element;
}

const Arrow: FC<ArrowProps> = ({ className, style, onClick, type, children }) => {
  const disabled = className?.indexOf('slick-disabled') !== -1;

  return (
    <div
      className={classnames(`${className} Carousel-arrow`, {
        ['Carousel-arrow-left']: type === 'prev',
        ['Carousel-arrow-right']: type === 'next',
        ['Carousel-arrow-disabled']: disabled,
      })}
      style={{ ...style }}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export default Arrow;
