import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { encodeURLParams } from '../../utils';
import config from '../../config';

export const authApi = createApi({
  reducerPath: 'authCall',
  baseQuery: fetchBaseQuery({
    baseUrl: `${config.api_url}/auth`,
    credentials: 'include',
    paramsSerializer: encodeURLParams,
  }),
  endpoints: (builder) => ({
    getToken: builder.mutation({
      query: (params) => ({
        url: 'token',
        method: 'POST',
        body: params,
      }),
    }),
    getValidate: builder.mutation({
      query: (params) => ({
        url: 'validate',
        method: 'POST',
        body: params,
      }),
    }),
    getRefreshToken: builder.mutation({
      query: (params) => ({
        url: 'refresh_token',
        method: 'POST',
        body: params,
      }),
    }),
  }),
});

export const { useGetTokenMutation, useGetValidateMutation, useGetRefreshTokenMutation } = authApi;
