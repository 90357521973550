import { useTranslation } from 'react-i18next';
import React, { FC, useState } from 'react';

import { generateUniqueId } from '../../../../utils';
import Button from '../../../../components/Button/Button';
import { Video } from '../../../../../types/generic';

import UpcomingEventDetail from './UpcomingEventDetail';
import './UpcomingLiveEvents.scss';

interface UpcomingLiveEventsProps {
  videos: Video[];
}

const MAX_ITEMS = 5;

const UpcomingLiveEvents: FC<UpcomingLiveEventsProps> = ({ videos }) => {
  const [showAll, setShowAll] = useState<boolean>(false);
  const { t } = useTranslation();

  return (
    <div className="UpcomingLiveEvents">
      <h2>{t('event_upcoming_title')}</h2>
      {!!videos?.length &&
        videos.map(
          (video, index) =>
            ((!showAll && index < MAX_ITEMS && video?.type !== 'AdContent') || showAll) && (
              <UpcomingEventDetail upcomingEvent={video} key={generateUniqueId()} />
            )
        )}
      {videos?.length > MAX_ITEMS && (
        <Button
          className="mt-8"
          text={
            showAll ? (t('event_upcoming_show_less') as string) : (t('event_upcoming_show_more') as string)
          }
          variant="transparent"
          onClick={() => setShowAll(!showAll)}
        />
      )}
    </div>
  );
};

export default UpcomingLiveEvents;
