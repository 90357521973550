// Assuming you have a slice named 'user' and endpoint 'getUserProfile'
import { createSelector } from '@reduxjs/toolkit'; // Adjust the import to your file structure

import { userApi } from '../services/user';
import { RootState } from '../index';

type UserState = ReturnType<typeof userApi.reducer>;

const selectUser = (state: RootState): UserState => state.user;

export const selectIsUserProfileFetched = createSelector(selectUser, (user) => {
  const queryKey = 'getUserProfile({})';

  return user.queries[queryKey]?.status === 'fulfilled';
});
