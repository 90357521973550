import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { slide as Menu } from 'react-burger-menu';
import React, { FC, Fragment, ReactElement, useCallback, useMemo, useState } from 'react';
import { Squash as Hamburger } from 'hamburger-react';

import Separator from '../Separator/Separator';
import SearchInput from '../SearchInput/SearchInput';
import { selectSiteBaseUrl } from '../../store/selectors/configSelectors';
import * as ROUTES from '../../routes/routes';

import './HamburgerMenu.scss';

interface HamburgerMenuProps {
  links: Array<Record<string, string>>;
  onClick: (path: string, label: string) => () => void;
}

const HamburgerMenu: FC<HamburgerMenuProps> = ({ links, onClick }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const siteBaseUrl = useSelector(selectSiteBaseUrl);

  const toggleMenu = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const menuSettings = useMemo(
    () => ({
      disableCloseOnEsc: false,
      right: true,
      noOverlay: true,
      customCrossIcon: false,
      customBurgerIcon: false,
      bodyClassName: 'disable-scroll',
      isOpen,
      onClose: toggleMenu,
    }),
    [isOpen, toggleMenu]
  );

  const hamburgerIconSettings = useMemo(
    () => ({
      toggled: isOpen,
      toggle: toggleMenu,
      rounded: true,
      label: t('hamburger_icon_label'),
    }),
    [t, isOpen, toggleMenu]
  );

  const onSearch = (value: string): void => {
    toggleMenu();
    navigate(`${siteBaseUrl}${ROUTES.SEARCH}?q=${value}`);
  };

  const onClickButton = (path, label) => () => {
    onClick(path, label)();
    toggleMenu();
  };

  const getItems = (): ReactElement[] | null =>
    links.length
      ? links.map(({ path, label }, index) => {
          const isLastElement = index === links.length - 1;
          const linkClass = `bm-item ${isLastElement ? 'mt-0' : ''}`;

          return (
            <Fragment key={label}>
              {isLastElement && <Separator />}
              <Link key={label} to={path} className={linkClass} onClick={onClickButton(path, label)}>
                {label}
              </Link>
            </Fragment>
          );
        })
      : null;

  return (
    <>
      <Hamburger {...hamburgerIconSettings} />
      <Menu {...menuSettings}>
        <SearchInput onSearch={onSearch} clearInput />
        {getItems()}
        {/* {isLoggedIn && (
          <Button
            className="bm-button"
            style={{ marginTop: 'auto' }}
            text={(isLoggedIn ? t('hamburger_button_logged_in') : t('hamburger_button_logged_out')) || ''}
            variant="primary"
            onClick={onClickButton(
              ROUTES.MY_PROFILE,
              isLoggedIn ? t('hamburger_button_logged_in') : t('hamburger_button_logged_out')
            )}
          />
        )} */}
      </Menu>
    </>
  );
};

export default HamburgerMenu;
