import React, { FC } from 'react';
import './GenericModal.scss';
import { Modal } from '@brightcove/studio-components';

interface GenericModalProps {
  title: string | React.ReactNode;
  show: boolean;
  children: JSX.Element;
  onClose: () => void;
}

const GenericModal: FC<GenericModalProps> = ({ title, show, onClose, children }) => (
  <Modal
    contentLabel="contentLabel"
    className="GenericModal"
    title={title}
    isOpen={show}
    onClose={() => {
      onClose();
    }}
    testName="basic-modal"
    buttons={<></>}
  >
    {children}
  </Modal>
);

export default GenericModal;
