import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import React, { FC } from 'react';
import _ from 'lodash';
import { Flex } from '@brightcove/studio-components';

import COLORS from '../../styles/_colors.module.scss';
import 'react-loading-skeleton/dist/skeleton.css';
import '../Grid/Grid.scss';
import './GridSkeleton.scss';

const GRID_SKELETON_MAX_TILE_COUNT = 4;

interface GridSkeletonProps {
  tileCount?: number;
}

const GridSkeleton: FC<GridSkeletonProps> = ({ tileCount = GRID_SKELETON_MAX_TILE_COUNT }) => (
  <SkeletonTheme baseColor={COLORS.grey40} highlightColor={COLORS.darkGrey}>
    <Flex className="Grid" flexDirection="column">
      <div className="Grid-content">
        {_.times(tileCount, (index: number) => (
          <div key={index} className="Skeleton-tile">
            <Skeleton className="Skeleton-tile-image" />
            <div className="mt-3">
              <Skeleton className="mb-2" height={25} />
              <Skeleton width="80%" height={25} />
            </div>
          </div>
        ))}
      </div>
    </Flex>
  </SkeletonTheme>
);

export default GridSkeleton;
