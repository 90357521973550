import { createSelector } from '@reduxjs/toolkit';

import { AuthState } from '../slices/authSlice';
import { RootState } from '../index';

// Select the auth slice state
const selectAuth = (state: RootState): AuthState => state.auth;

export const selectToken = createSelector(selectAuth, (auth) => auth.token);
export const selectLogged = createSelector(selectAuth, (auth) => auth.isLogged);
export const selectUser = createSelector(selectAuth, (auth) => auth.user);
export const selectValidated = createSelector(selectAuth, (auth) => auth.validated);
export const selectUserExtId = createSelector(selectUser, (user) => user?.extId || '');
