import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import React, { FC, useMemo } from 'react';
import classNames from 'classnames';

import { useGetSettingsMetaQuery } from '../../store/services/metadata';
import { selectSiteBaseUrl } from '../../store/selectors/configSelectors';
import { selectLogged, selectUser, selectValidated } from '../../store/selectors/authSelectors';
import * as ROUTES from '../../routes/routes';
import PageHead from '../../components/PageHead/PageHead';
import DataStatusHandler from '../../components/DataStatusHandler/DataStatusHandler';
import ContainerWithTitle from '../../components/ContainerWithTitle/ContainerWithTitle';

import SettingsList from './components/SettingsList';
import './Settings.scss';

export const settingsId = 'Settings';
/**
 * The Settings page as you can see has 3 columns, this comes from a design requirement.
 * I divided all the settings in 3 constants, the first one gets dynamic links injected from the API response.
 * For tablet there should be 2 columns and for mobile only 1, so that's why I'm doing the spread on the arrays.
 * Everything is rendered but controlled from CSS with media queries
 */
const Settings: FC = () => {
  const isLoggedIn = useSelector(selectLogged);
  const isValidated = useSelector(selectValidated);

  const siteBaseUrl = useSelector(selectSiteBaseUrl);
  const user = useSelector(selectUser);
  const { t } = useTranslation();
  const { data, isLoading, isError } = useGetSettingsMetaQuery(undefined, {
    refetchOnMountOrArgChange: true,
    skip: isValidated === null,
  });

  const customLinksMapped = useMemo(
    () =>
      data?.items?.map(({ id, title }) => ({
        path: `${siteBaseUrl}/page/${id}`,
        text: title,
      })) || [],
    [data, siteBaseUrl]
  );

  const firstColumn = useMemo(
    () => [
      {
        title: t('settings_app_settings_header'),
        items: [
          { path: `${siteBaseUrl}${ROUTES.LANGUAGE}`, text: t('settings_change_language') },
          ...customLinksMapped,
          { path: `${siteBaseUrl}${ROUTES.ABOUT}`, text: t('settings_about') },
        ],
      },
    ],
    [customLinksMapped, siteBaseUrl, t]
  );

  const secondColumn = useMemo(
    () => [
      {
        title: t('settings_account_header'),
        items: [{ path: `${siteBaseUrl}${ROUTES.MY_PROFILE}`, text: t('my_profile_header') }],
      },
    ],
    [siteBaseUrl, t]
  );

  const thirdColumn = useMemo(
    () => [
      ...(user
        ? [
            {
              title: t('settings_profile_header'),
              items: [
                { text: `${user.name}`, subHeader: t('settings_name_subheader') },
                { text: `${user.email}`, subHeader: t('settings_email_subheader'), isEmail: true },
              ],
            },
          ]
        : []),
    ],
    [t, user, isLoggedIn]
  );

  return (
    <DataStatusHandler isLoading={isLoading} isError={isError}>
      <ContainerWithTitle
        title={t('header_settings') || 'Settings'}
        containerClass={classNames({ ['Settings-center']: !isLoggedIn })}
      >
        <PageHead />
        <SettingsList className="Settings-desktop" column={firstColumn} />
        {isLoggedIn && <SettingsList className="Settings-desktop" column={secondColumn} />}

        {isLoggedIn && <SettingsList className="Settings-desktop" column={thirdColumn} />}

        <SettingsList className="Settings-tablet" column={firstColumn} />
        {isLoggedIn && (
          <SettingsList className="Settings-tablet" column={[...thirdColumn, ...secondColumn]} />
        )}

        <SettingsList
          className="Settings-mobile"
          column={isLoggedIn ? [...thirdColumn, ...secondColumn, ...firstColumn] : [...firstColumn]}
        />
      </ContainerWithTitle>
    </DataStatusHandler>
  );
};

export default Settings;
