import React from 'react';

export const validateHTML = (html?: string, tag = 'div'): string | null => {
  if (!html) return null;

  try {
    const element = document.createElement(tag);
    element.innerHTML = html;

    return element.innerHTML;
  } catch (error) {
    return null;
  }
};

const splitScriptElements = (html?: string): string[] | null => {
  if (!html) return [];

  return html.match(/<script\b[^>]*>[\s\S]*?<\/script>/gi);
};

export const htmlStringToReactComponents = (additionalHtml?: string): JSX.Element[] | undefined => {
  if (!additionalHtml) return;

  try {
    return splitScriptElements(additionalHtml)?.map((html, index) => {
      const dom = new DOMParser().parseFromString(html, 'text/html');
      const el = dom.documentElement.querySelector(':not(html):not(head):not(body)');
      const attributes = Array.from(el?.attributes || []);
      const NodeName = el?.nodeName.toLowerCase() as keyof JSX.IntrinsicElements;
      const finalAttributes = Object.fromEntries([...attributes].map(({ name, value }) => [name, value]));

      return (
        <NodeName {...finalAttributes} key={index}>
          {el?.innerHTML}
        </NodeName>
      );
    });
  } catch (error) {
    return;
  }
};
