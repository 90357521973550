import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import React, { FC, useMemo } from 'react';

import { selectSiteConfig, selectSiteLanguage } from '../../store/selectors/configSelectors';
import { useDynamicContentClickHandler } from '../../hooks/useDynamicContentClickHandler';
import PageHead from '../../components/PageHead/PageHead';
import ContainerWithTitle from '../../components/ContainerWithTitle/ContainerWithTitle';
import { SettingsData } from '../../../types/page';
import './CustomPage.scss';

declare global {
  interface Window {
    // disabling eslint just for this case as handlebars is being used in the window object and imported from the CDN
    // eslint-disable-next-line
    Handlebars: any;
  }
}

interface CustomPageProps {
  // TODO: I'm not quite sure we can type this as this will be controlled by the user for now I leave this but this probably will have to be typed in the future considering the keys that ALLWAYS will come for a custom page and making it a Partial or including all the other possible elements that could come in the response
  data: SettingsData;
}

const CustomPage: FC<CustomPageProps> = ({ data }) => {
  if (!data) return null;

  const { includeTitleBar, content } = data;
  const siteConfig = useSelector(selectSiteConfig);
  const siteLanguage = useSelector(selectSiteLanguage);
  const { i18n } = useTranslation();
  const resources = i18n.getDataByLanguage(siteLanguage);
  const { onDynamicContentClick } = useDynamicContentClickHandler();

  const title = useMemo(
    () => (includeTitleBar ? data?.head?.tags?.find((tag) => tag._tag === 'title')?._body : undefined),
    [data, includeTitleBar]
  );

  const context = useMemo(
    () => ({
      ...siteConfig,
      labels: resources?.translation,
    }),
    [siteConfig, resources]
  );

  const html = useMemo(() => {
    if (content) {
      const template = window.Handlebars.compile(content);

      return { __html: template(context) };
    }
  }, [content, context]);

  return (
    <div className="Custom-Page">
      <ContainerWithTitle title={title}>
        <PageHead title={data?.name} />
        <div dangerouslySetInnerHTML={html} onClick={(e) => onDynamicContentClick(e, data?.name)} />
      </ContainerWithTitle>
    </div>
  );
};

export default CustomPage;
