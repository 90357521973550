import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import React, { FC, useEffect } from 'react';

import ErrorPage from '../ErrorPage/ErrorPage';
import { getLanguageCode, supportedLanguages } from '../../utils';
import { useGetHomePageQuery } from '../../store/services/page';
import { selectPreviewToken, selectSiteLanguage } from '../../store/selectors/configSelectors';
import { selectValidated } from '../../store/selectors/authSelectors';
import { useDisablePage } from '../../hooks/useDisablePage';
import Sections from '../../components/Sections/Sections';
import PageHead from '../../components/PageHead/PageHead';
import Loading from '../../components/Loading/Loading';
import { HomeData } from '../../../types/page';

import './Home.scss';

type Props = {
  customData?: HomeData;
};

const Home: FC<Props> = ({ customData }) => {
  const { lang } = useParams();
  const languages = supportedLanguages.map((lang) => getLanguageCode(lang.value));
  const currentLanguage = useSelector(selectSiteLanguage) || 'en-us';
  const pvtk = useSelector(selectPreviewToken);
  const isValidHomePage = !lang || languages.includes(lang);
  const isValidated = useSelector(selectValidated);

  useEffect(() => {
    const originUrl = localStorage.getItem('originUrl');

    if (originUrl) {
      window.location.href = originUrl;
      localStorage.removeItem('originUrl');
    }
  }, []);

  const { data, isFetching, isError } = useGetHomePageQuery(pvtk ? { params: { pvtk } } : {}, {
    refetchOnMountOrArgChange: true,
    skip: !currentLanguage || isValidated === null || !!customData,
  });

  const sourceData = customData || data;

  useDisablePage(!!(sourceData?.isPreview && pvtk));

  if (isFetching) {
    return <Loading />;
  }

  if (isError || !isValidHomePage) {
    return <ErrorPage />;
  } else {
    return (
      <div className="home-container">
        <PageHead title={sourceData?.name} />
        <div className="main-content">
          <Sections sections={sourceData?.sections} />
        </div>
      </div>
    );
  }
};

export default Home;
