import { Dispatch } from '@reduxjs/toolkit';

import { removeLanguangeBlocker } from '../utils';
import { clearToken, setLogin } from '../store/slices/authSlice';
import { removeLocalFavorites, removeLocalProgresses } from '../store/localStorage/utils';

export const logout = (dispatch: Dispatch): void => {
  removeLanguangeBlocker();
  removeLocalFavorites();
  removeLocalProgresses();
  dispatch(clearToken());
  dispatch(setLogin(false));
};
