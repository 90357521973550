import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { getFullLanguageCode, supportedLanguages, encodeURLParams, getLanguage } from '../../utils';
import config from '../../config';
import { SearchData } from '../../../types/search';
import {
  ErrorData,
  EventData,
  EventDetailData,
  HomeData,
  PageType,
  SeriesData,
  SeriesDetailData,
  VideoData,
} from '../../../types/page';
import { GenericData, SeasonData } from '../../../types/generic';

type PageData = {
  type: PageType;
  [key: string]: any; // eslint-disable-line @typescript-eslint/no-explicit-any
};

interface Args {
  id?: string;
  skip?: boolean;
  params?: Record<string, string | number>;
}

interface SectionDetailArgs {
  pageId: string;
  sectionId: string;
  skip?: boolean;
  params?: Record<string, string | number>;
}

export const pageApi = createApi({
  reducerPath: 'page',
  baseQuery: fetchBaseQuery({
    baseUrl: config.api_url,
    prepareHeaders: (headers) => {
      const language = getLanguage();
      headers.set('user-language', getFullLanguageCode(language, supportedLanguages) as string);

      return headers;
    },
    paramsSerializer: encodeURLParams,
    credentials: 'include',
  }),
  endpoints: (builder) => ({
    getHomePage: builder.query<HomeData, Args>({
      query: () => 'home',
    }),
    getSearchPage: builder.query<SearchData, Args>({
      query: ({ params }) => ({ url: 'search', params }),
    }),
    getSearchResults: builder.query<GenericData, Args>({
      query: ({ params }) => ({ url: 'search/results', params }),
    }),
    getVideoDetailPage: builder.query<VideoData, Args>({
      query: ({ id, params }) => ({ url: `detail/${id}`, params }),
    }),
    getSectionDetailPage: builder.query<SeriesData, SectionDetailArgs>({
      query: ({ pageId, sectionId, params }) => ({ url: `section/${pageId}/${sectionId}`, params }),
    }),
    getSeriesPage: builder.query<SeriesDetailData, Args>({
      query: ({ id, params }) => ({ url: `series/${id}`, params }),
    }),
    getSeasonData: builder.query<SeasonData, Args>({
      query: ({ id, params }) => ({ url: `season/${id}`, params }),
    }),
    getEventDetailPage: builder.query<EventDetailData, Args>({
      query: ({ id, params }) => ({ url: `event/${id}`, params }),
    }),
    getEventLandingPage: builder.query<EventData, Args>({
      query: () => '/events',
    }),
    getErrorPage: builder.query<ErrorData, Args>({
      query: () => '/error',
    }),
    getPageById: builder.query<PageData | null, Args>({
      query: ({ id, params }) => ({ url: `page/${id}`, params }),
    }),
  }),
});

export const {
  useGetHomePageQuery,
  useGetSearchPageQuery,
  useGetSearchResultsQuery,
  useGetVideoDetailPageQuery,
  useGetSectionDetailPageQuery,
  useGetSeriesPageQuery,
  useGetSeasonDataQuery,
  useGetEventLandingPageQuery,
  useGetErrorPageQuery,
  useGetEventDetailPageQuery,
  useGetPageByIdQuery,
} = pageApi;
