import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import React, { FC } from 'react';

import { selectSiteConfig } from '../../store/selectors/configSelectors';
import config from '../../config';
import ContainerWithTitle from '../../components/ContainerWithTitle/ContainerWithTitle';
import './About.scss';

const About: FC = () => {
  const { t } = useTranslation();
  const siteConfig = useSelector(selectSiteConfig);

  return (
    <ContainerWithTitle containerClass="About-container" title={t('header_about') || 'About'}>
      <div className="About-title">{t('about_app_version_title')}</div>
      <div className="About-text">{config.version}</div>
      <div className="About-title">{t('about_api_version_title')}</div>
      <div className="About-text">{siteConfig?.version}</div>
    </ContainerWithTitle>
  );
};

export default About;
