import { AnyAction, Dispatch, MiddlewareAPI } from '@reduxjs/toolkit';

import { setMaintenanceMode } from './slices/configSlice';

/**
 * This middleware will intercept all 503 errors and dispatch an action to set the Maintenance mode
 *
 * @param api
 */
export const maintenanceModeMiddleware = (api: MiddlewareAPI) => (next: Dispatch) => (action: AnyAction) => {
  if (action?.payload?.status === 503) {
    const errorMessage = action.payload.data.message;
    api.dispatch(setMaintenanceMode(errorMessage));
  }

  return next(action);
};
