import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React, { ElementType, FC, lazy, Suspense } from 'react';
import { Flex } from '@brightcove/studio-components';

import Tile from '../Tile/Tile';
import GridSkeleton from '../SkeletonUI/GridSkeleton';
import './Grid.scss';
import { useGetPageUrl } from '../../hooks/useGetPageUrl';
import { GridSection } from '../../../types/section';
import { GridItem, TileItem } from '../../../types/item';

const GRID_DEFAULT_COLUMN_COUNT = 4;

interface GridProps {
  section?: GridSection;
  items?: GridItem[];
  columnCount?: number;
  onFavoriteChange?: (removedId?: string) => void;
  gridName?: string;
}

const Grid: FC<GridProps> = ({
  columnCount = GRID_DEFAULT_COLUMN_COUNT,
  section,
  items,
  onFavoriteChange,
  gridName = '',
}) => {
  const { t } = useTranslation();
  const url = useGetPageUrl({
    id: section?.id,
    pageId: section?.content?.id,
    type: section?.type,
  });
  const name = section?.name || gridName;
  const templateRef = section?.templateRef;
  const itemsFromSection = section?.data?.items || null;
  const currentItems = itemsFromSection || items;
  const DynamicComponent = templateRef ? (lazy(() => import(`${templateRef}`)) as ElementType) : undefined;

  if (currentItems?.length === 0) {
    return null;
  }

  return templateRef && DynamicComponent ? (
    <Suspense fallback={<GridSkeleton />}>
      <DynamicComponent {...{ name, currentItems }} />
    </Suspense>
  ) : (
    <Flex className="Grid" flexDirection="column">
      <div className="Carousel-header-container">
        {name && <h2 className="Grid-header">{name}</h2>}
        {url && (
          <Link to={url} className="Carousel-view-more">
            {t('section_view_more')}
          </Link>
        )}
      </div>
      <div className="Grid-content" style={{ gridTemplateColumns: `repeat(${columnCount}, 1fr)` }}>
        {currentItems
          ? currentItems.map((item, index) => (
              <Tile key={index} {...(item as TileItem)} onFavoriteChange={onFavoriteChange} />
            ))
          : null}
      </div>
    </Flex>
  );
};

export default Grid;
