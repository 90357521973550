import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import React, { FC } from 'react';

import ErrorPage from '../ErrorPage/ErrorPage';
import { useGetSeriesPageQuery } from '../../store/services/page';
import { selectPreviewToken } from '../../store/selectors/configSelectors';
import { selectValidated } from '../../store/selectors/authSelectors';
import Sections from '../../components/Sections/Sections';
import PageHead from '../../components/PageHead/PageHead';
import Loading from '../../components/Loading/Loading';
import { SeriesDetailData } from '../../../types/page';

type Props = {
  customData?: SeriesDetailData;
};

const SeriesDetailPage: FC<Props> = ({ customData }) => {
  const isValidated = useSelector(selectValidated);

  const pvtk = useSelector(selectPreviewToken);
  const params = useParams();
  const { data, isFetching, isError } = useGetSeriesPageQuery(
    {
      id: params.id,
      ...(pvtk && { params: { pvtk } }),
    },
    {
      refetchOnMountOrArgChange: true,
      skip: isValidated === null || !!customData,
    }
  );

  const sourceData = customData || data;

  if (isFetching) {
    return <Loading />;
  }

  if (isError) {
    return <ErrorPage />;
  }

  return (
    <div>
      <PageHead title={sourceData?.name} />
      <div className="main-content p-5">
        <Sections sections={sourceData?.sections} />
      </div>
    </div>
  );
};

export default SeriesDetailPage;
