import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import React, { FC } from 'react';

import ErrorPage from '../ErrorPage/ErrorPage';
import { useGetVideoDetailPageQuery } from '../../store/services/page';
import { selectPreviewToken } from '../../store/selectors/configSelectors';
import { selectValidated } from '../../store/selectors/authSelectors';
import { validateHTML } from '../../helpers/validators';
import Sections from '../../components/Sections/Sections';
import PageHead from '../../components/PageHead/PageHead';
import Loading from '../../components/Loading/Loading';
import { VideoData } from '../../../types/page';

import './VideoDetail.scss';

type Props = {
  customData?: VideoData;
};

const VideoDetail: FC<Props> = ({ customData }) => {
  const pvtk = useSelector(selectPreviewToken);
  const params = useParams();
  const isValidated = useSelector(selectValidated);

  const { data, isLoading, isError } = useGetVideoDetailPageQuery(
    {
      id: params.id,
      ...(pvtk && { params: { pvtk } }),
    },
    {
      refetchOnMountOrArgChange: true,
      skip: isValidated === null || !!customData,
    }
  );

  const sourceData = customData || data;

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <ErrorPage />;
  }

  const additionalHtml = validateHTML(sourceData?.content);

  return (
    <div className="VideoDetail">
      <PageHead title={sourceData?.name} />

      <div className="main-content">
        <Sections sections={sourceData?.sections} />

        {!!additionalHtml && (
          <div className="additional-html" dangerouslySetInnerHTML={{ __html: additionalHtml }} />
        )}
      </div>
    </div>
  );
};

export default VideoDetail;
