import { useTranslation } from 'react-i18next';
import React, { FC, useState } from 'react';
import classnames from 'classnames';
import { DropdownMenu as BrightcoveDropdownMenu, DropdownMenuItem } from '@brightcove/studio-components';
import './DropdownMenu.scss';

export type Option = {
  value: string;
  label: string;
  locale_key: string;
};

interface DropdownMenuProps {
  btnLabel: string;
  btnLook?: string;
  className?: string;
  options: Option[];
  onSelect: (val: string) => void;
  value?: Option;
}

const DropdownMenu: FC<DropdownMenuProps> = ({
  btnLabel,
  btnLook,
  className,
  options,
  onSelect,
  value = null,
}) => {
  const [selectedOption, setSelectedOption] = useState<Option | null>(value);
  const { t } = useTranslation();

  const onClick = (option): void => {
    setSelectedOption(option);
    onSelect?.(option);
  };

  const selectedLabel = selectedOption?.locale_key ? t(selectedOption?.locale_key) : selectedOption?.label;

  return (
    <BrightcoveDropdownMenu
      label={selectedLabel || btnLabel}
      className={classnames('Dropdown-menu', className)}
      buttonProps={{
        look: `${btnLook || 'tertiary'}`,
        className: 'Dropdown-menu-button',
      }}
    >
      <>
        {options.map((option) => (
          <DropdownMenuItem key={option.value} className="Dropdown-menu-item" onClick={() => onClick(option)}>
            {t(option.locale_key, { defaultValue: option.label })}
          </DropdownMenuItem>
        ))}
      </>
    </BrightcoveDropdownMenu>
  );
};

export default DropdownMenu;
