import { useEffect } from 'react';

export const useDisablePage = (isPreview: boolean): void => {
  useEffect(() => {
    if (isPreview) {
      // this disables keyboard navigation for preview mode
      document.onkeydown = () => false;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      document.getElementsByClassName('app-body')[0].style.pointerEvents = 'none';

      return () => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        document.getElementsByClassName('app-body')[0].style.pointerEvents = 'unset';
      };
    }
  }, [isPreview]);
};
