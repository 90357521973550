import { useNavigate, useParams } from 'react-router-dom';
import { FaVolumeUp, FaVolumeMute } from 'react-icons/fa';
import React, { FC, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { sortBy } from 'lodash';
import classNames from 'classnames';

import UpcomingLiveEvents from '../UpcomingLiveEvents/UpcomingLiveEvents';
import {
  PRE_EVENT,
  POST_EVENT,
  LIVE_EVENT,
  getTypeOfEvent,
  getUrlPath,
  isSameDomain,
  isValidCta,
} from '../../../../utils';
import ReactPlayer from '../../../../components/ReactPlayer/ReactPlayer';
import Countdown from '../../../../components/Countdown/Countdown';
import Button from '../../../../components/Button/Button';
import { SVGImage } from '../../../../assets/images';
import { EventHeroSection, PlayerSection } from '../../../../../types/section';
import { EventImage, HeroItem } from '../../../../../types/item';
import { EventType, Video } from '../../../../../types/generic';

import './HeroEvent.scss';

interface HeroEventProps {
  section: EventHeroSection;
}

const HeroEvent: FC<HeroEventProps> = ({ section }) => {
  const { preEventBanner, postEventBanner, id, videosBanner, trailerVideo, finalVideo } = section;
  const navigate = useNavigate();
  const params = useParams();
  const [event, setEvent] = useState<HeroItem<EventImage>>();
  const [muted, setMuted] = useState(true);
  const [eventType, setEventType] = useState<EventType>();
  const [playerSection, setPlayerSection] = useState<PlayerSection>();
  const background = `linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000000 78.12%), url(${
    event?.image?.url || SVGImage.DefaultThumbnail
  })`;
  const sortedVideos = useMemo(() => {
    const filtered = videosBanner?.reduce<Video[]>((acc, video) => {
      if (video.endDate && moment(video.endDate).isBefore(moment())) {
        return acc;
      }

      acc.push(video);

      return acc;
    }, []);

    return filtered?.sort((a, b) => {
      const aStartDate = moment(a.startDate);
      const bStartDate = moment(b.startDate);

      return aStartDate.diff(moment()) - bStartDate.diff(moment());
    });
  }, [videosBanner]);

  const upcomingVideos = sortBy(
    videosBanner?.filter((item) => moment(item.endDate).isAfter(moment())),
    ['startDate']
  );

  useEffect(() => {
    if (sortedVideos && sortedVideos[0]) {
      const { startDate, endDate } = sortedVideos[0];
      const eventType = getTypeOfEvent(startDate, endDate || '');

      if (eventType === PRE_EVENT && preEventBanner) {
        setEvent(preEventBanner as HeroItem<EventImage>);
      }

      setPlayerSection({
        eventId: params.id,
        type: 'Player',
        id,
      });

      setEventType(eventType);
    } else {
      if (postEventBanner) {
        setEvent(postEventBanner as HeroItem<EventImage>);
        setEventType('postEvent');
        setPlayerSection({
          eventId: params.id,
          type: 'Player',
          id: finalVideo.brightcoveVideoId,
        });
      }
    }
  }, [sortedVideos]);

  const renderPreEventCtaButtons = (onlyMobile = false): JSX.Element | null => {
    const hasCta = event?.cta1 || event?.cta2;

    if (!hasCta) {
      return null;
    }

    return (
      <div
        className={classNames('HeroEvent-buttons-container', { mobile: onlyMobile, desktop: !onlyMobile })}
      >
        {isValidCta(event?.cta1) && (
          <Button
            text={event.cta1.label}
            variant="primary"
            onClick={() => {
              const destination = event.cta1.url;

              if (isSameDomain(destination)) {
                navigate(getUrlPath(destination));
              } else {
                window.open(destination, '_blank');
              }
            }}
          />
        )}

        {isValidCta(event?.cta2) && (
          <Button
            text={event.cta2.label}
            variant="secondary"
            showArrow
            onClick={() => {
              const destination = event.cta2.url;

              if (isSameDomain(destination)) {
                navigate(getUrlPath(destination));
              } else {
                window.open(destination, '_blank');
              }
            }}
          />
        )}
      </div>
    );
  };

  const renderPreEventCountdown = (onlyMobile = false): JSX.Element | null => {
    if (!sortedVideos?.[0]?.startDate) {
      return null;
    }

    return (
      <Countdown
        className={classNames({ 'Countdown-mobile': onlyMobile, 'Countdown-desktop': !onlyMobile })}
        key={sortedVideos[0].startDate}
        time={sortedVideos[0].startDate}
        onComplete={() => setEventType(LIVE_EVENT)}
      />
    );
  };

  const renderEvent = (type: EventType): React.JSX.Element => {
    if (type === PRE_EVENT) {
      return (
        <div
          className={`HeroEvent-container`}
          style={{
            background:
              trailerVideo.brightcoveVideoId && trailerVideo.allowPlayback ? 'transparent' : background,
          }}
        >
          <div className="HeroEvent-container-video-background">
            {trailerVideo.brightcoveVideoId && trailerVideo.allowPlayback && (
              <ReactPlayer
                section={{
                  video: trailerVideo.brightcoveVideoId,
                  allowPlayback: trailerVideo.allowPlayback,
                  allowInteraction: false,
                }}
                muted={muted}
                options={{ autoplay: true, controls: false, loop: true }}
              />
            )}

            <div className="HeroEvent-inner-container">
              <div className="HeroEvent-left-container">
                <p className="HeroEvent-text-eyebrow">{event?.eyebrowText}</p>
                <p className="HeroEvent-text-title">{event?.title}</p>
                <p className="HeroEvent-text-description">{event?.description}</p>
                {renderPreEventCtaButtons()}
              </div>

              {renderPreEventCountdown()}
            </div>

            {trailerVideo.brightcoveVideoId && trailerVideo.allowPlayback && (
              <>
                {muted ? (
                  <FaVolumeMute
                    className="HeroEvent-mute"
                    onClick={() => {
                      setMuted(false);
                    }}
                  />
                ) : (
                  <FaVolumeUp
                    className="HeroEvent-mute"
                    onClick={() => {
                      setMuted(true);
                    }}
                  />
                )}
              </>
            )}
          </div>

          {renderPreEventCtaButtons(true)}

          {renderPreEventCountdown(true)}
        </div>
      );
    } else if (type === POST_EVENT) {
      return (
        <div className={`HeroEvent-container mb-12`} style={{ background }}>
          <div className="p-4">
            {event?.eyebrowText && <p className="HeroEvent-text-eyebrow">{event?.eyebrowText}</p>}
            {event?.title && <p className="HeroEvent-text-title">{event?.title}</p>}
            {event?.description && <p className="HeroEvent-text-description">{event?.description}</p>}

            {(event?.cta1 || event?.cta2) && (
              <div className="HeroEvent-buttons-container">
                {!!event.cta1?.label?.length && !!event.cta1?.url?.length && (
                  <Button
                    text={event.cta1.label}
                    variant="primary"
                    onClick={() => {
                      const destination = event.cta1.url;

                      if (isSameDomain(destination)) {
                        navigate(getUrlPath(destination));
                      } else {
                        window.open(destination, '_blank');
                      }
                    }}
                  />
                )}
                {!!event.cta2?.label?.length && !!event.cta2?.url?.length && (
                  <Button
                    text={event.cta2.label}
                    variant="secondary"
                    showArrow
                    onClick={() => {
                      const destination = event.cta2.url;

                      if (isSameDomain(destination)) {
                        navigate(getUrlPath(destination));
                      } else {
                        window.open(destination, '_blank');
                      }
                    }}
                  />
                )}
              </div>
            )}

            {finalVideo.brightcoveVideoId && (
              <div
                className="HeroEvent-play-icon mt-4"
                onClick={() => {
                  setEventType(LIVE_EVENT);
                }}
              >
                <img src={SVGImage.PlayWhiteIcon} alt="NetApp Logo" />
              </div>
            )}
          </div>
        </div>
      );
    } else if (type === LIVE_EVENT) {
      return (
        <div className="HeroEvent-player mt-12 mb-12">
          <ReactPlayer section={playerSection as PlayerSection} />
        </div>
      );
    } else {
      return <></>;
    }
  };

  return (
    <>
      {renderEvent(eventType)}
      {upcomingVideos?.length ? <UpcomingLiveEvents videos={upcomingVideos} /> : null}
    </>
  );
};

export default HeroEvent;
