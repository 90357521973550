import Slider, { Settings } from 'react-slick';
import { useNavigate } from 'react-router-dom';
import React, { FC, useMemo } from 'react';

import { getUrlPath, isSameDomain, isValidCta } from '../../../../utils';
import Arrow from '../../../../components/Carousel/Arrow';
import Button from '../../../../components/Button/Button';
import { SVGImage } from '../../../../assets/images';
import { HeroSection } from '../../../../../types/section';
import { HeroItem } from '../../../../../types/item';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Hero.scss';

interface HeroProps {
  section: HeroSection;
}

const Hero: FC<HeroProps> = ({
  section: {
    data: { items },
    customizations,
  },
}) => {
  const navigate = useNavigate();
  const settings: Settings = {
    dots: true,
    dotsClass: 'Hero-slider-dots',
    infinite: true,
    arrows: true,
    speed: 500,
    className: 'Hero-slider',
    slidesToScroll: 1,
    slidesToShow: 1,
    prevArrow: <></>,
    nextArrow: (
      <Arrow type="next">
        <img src={SVGImage.ArrowForwardCarousel} alt="NetApp Tv Arrow" />
      </Arrow>
    ),
  };

  const serializeItem = (item: HeroItem): HeroItem => {
    const customization = customizations.find((customItem) => customItem.itemId === item.id);

    if (!customization) return item;

    return {
      ...item,
      name: customization.title || item.name,
      description: customization.description || item.description,
      image: customization.image?.url || item.image,
      eyebrow: customization.eyebrow || item.eyebrow,
      cta1: customization.cta1 || item.cta1,
      cta2: customization.cta2 || item.cta2,
    };
  };

  const list = useMemo(() => items.map(serializeItem), [items]);

  return (
    <Slider {...settings}>
      {list?.map((item, index) => {
        const backgroundImage =
          typeof item.image === 'object' && item.image?.url
            ? `url(${item.image?.url})`
            : `url(${(item.image as string)?.length ? item.image : SVGImage.DefaultThumbnail})`;

        return (
          <div key={index}>
            <div className="Hero-slide" style={{ backgroundImage }}>
              <div className="Hero-slide-overlay-content">
                <div className="Hero-slide-overlay-content-text">
                  <p className="Hero-text-eyebrow" title={item?.eyebrow}>
                    {item?.eyebrow}
                  </p>
                  <p className="Hero-text-title" title={item?.name}>
                    {item.name}
                  </p>
                  <p className="Hero-text-description" title={item?.description}>
                    {item.description}
                  </p>
                </div>

                {(item.cta1 || item.cta2) && (
                  <div className="Hero-buttons-container">
                    {isValidCta(item?.cta1) && (
                      <Button
                        text={item.cta1.label}
                        variant="primary"
                        onClick={() => {
                          const destination = item.cta1.url;

                          if (isSameDomain(destination)) {
                            navigate(getUrlPath(destination));
                          } else {
                            window.open(destination, '_blank');
                          }
                        }}
                      />
                    )}
                    {isValidCta(item?.cta2) && (
                      <Button
                        text={item.cta2.label}
                        variant="secondary"
                        showArrow
                        onClick={() => {
                          const destination = item.cta2.url;

                          if (isSameDomain(destination)) {
                            navigate(getUrlPath(destination));
                          } else {
                            window.open(destination, '_blank');
                          }
                        }}
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        );
      })}
    </Slider>
  );
};

export default Hero;
