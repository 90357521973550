import { isEqual } from 'lodash';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { removeLocalConfig } from '../localStorage/utils';
import {
  getFullLanguageCode,
  getLanguageCode,
  SupportedLanguage,
  supportedLanguages,
  SupportedLanguagesCodes,
} from '../../utils';
import { TileItem } from '../../../types/item';
import { ContinuityData } from '../../../types/generic';

export type FavoriteItem = TileItem & {
  removed?: boolean;
};

export type FavoritesConfig = {
  updated_at?: string;
  items: FavoriteItem[];
};

export type ProgressItem = TileItem;

type LinkConfig = {
  urlSafeName: string;
  title: string;
  id: string;
};

export interface FooterConfig {
  templateRef: string;
  data: LinkConfig[];
}

export interface HeaderMenuConfig {
  templateRef: string;
  data: LinkConfig[];
}

export interface SiteConfig {
  language: string;
  version: string;
  headerMenu: HeaderMenuConfig;
  headerHtml: string;
  footer: FooterConfig;
  footerHtml: string;
  labels: Record<string, string>;
  serviceMessages: string[] | null;
}

// Let's try to keep this the most typed possible
export interface ConfigState {
  previewToken: string | null;
  displayLogIn: boolean;
  languages: SupportedLanguage[];
  siteLanguage: string;
  siteLanguageLong: SupportedLanguagesCodes;
  siteBaseUrl: string;
  siteConfig: SiteConfig | null;
  maintenanceMode: string | null;
  currentVideoProgress: number;
  favorites: string[];
  favoriteLoadingId: string | null;
  continuity: ContinuityData;
  loadingLanguage: boolean;
}

const initialState: ConfigState = {
  previewToken: null,
  displayLogIn: true,
  languages: [],
  siteLanguage: '',
  siteLanguageLong: 'en-us',
  siteBaseUrl: '',
  siteConfig: null,
  maintenanceMode: null,
  currentVideoProgress: 0,
  favorites: [],
  favoriteLoadingId: null,
  continuity: {
    items: [],
  },
  loadingLanguage: false,
};

const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    showLogIn: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.displayLogIn = true;
    },
    hideLogIn: (state) => {
      state.displayLogIn = false;
    },
    setLanguages: (state, action: PayloadAction<SupportedLanguage[]>) => {
      state.languages = action.payload;
    },
    setSiteLanguageInfo: (state, action: PayloadAction<string>) => {
      const language = action.payload;
      const languageCode = getLanguageCode(language);
      const languageLong = getFullLanguageCode(languageCode, supportedLanguages) as SupportedLanguagesCodes;
      const baseUrl = `${languageCode !== 'en' ? `/${languageCode}` : ''}`;

      state.siteLanguage = language;
      state.siteLanguageLong = languageLong;
      state.siteBaseUrl = baseUrl;
    },
    setSiteConfig: (state, action: PayloadAction<SiteConfig>) => {
      removeLocalConfig();
      state.siteConfig = action.payload;
      state.siteLanguageLong = action.payload.language as SupportedLanguagesCodes;
    },
    setPreviewToken: (state, action: PayloadAction<string>) => {
      state.previewToken = action.payload;
    },
    setMaintenanceMode: (state, action: PayloadAction<string>) => {
      state.maintenanceMode = action.payload;
    },
    setCurrentVideoProgress: (state, action: PayloadAction<number>) => {
      state.currentVideoProgress = action.payload;
    },
    setFavorites: (state, action: PayloadAction<string[] | undefined>) => {
      state.favorites = action.payload || initialState.favorites;
    },
    setFavoriteLoadingId: (state, action: PayloadAction<string>) => {
      state.favoriteLoadingId = action.payload;
    },
    removeFavoriteLoadingId: (state) => {
      state.favoriteLoadingId = null;
    },
    setContinuity: (state, action: PayloadAction<ContinuityData | undefined>) => {
      if (!isEqual(state.continuity, action.payload)) {
        state.continuity = action.payload || initialState.continuity;
      }
    },
    setLoadingLanguage: (state, action: PayloadAction<boolean>) => {
      state.loadingLanguage = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  showLogIn,
  hideLogIn,
  setLanguages,
  setSiteLanguageInfo,
  setSiteConfig,
  setPreviewToken,
  setMaintenanceMode,
  setCurrentVideoProgress,
  setFavorites,
  setFavoriteLoadingId,
  removeFavoriteLoadingId,
  setContinuity,
  setLoadingLanguage,
} = configSlice.actions;

export default configSlice.reducer;
