import React, { FC, ReactNode } from 'react';

import Loading from '../Loading/Loading';
import ErrorPage from '../../routes/ErrorPage/ErrorPage';

interface DataStatusHandlerProps {
  isLoading: boolean;
  isError: boolean;
  children: ReactNode;
}

/**
 * Generic wrapper for components that use isLoading and isError
 *
 * @param isLoading
 * @param isError
 * @param children
 * @constructor
 */
const DataStatusHandler: FC<DataStatusHandlerProps> = ({ isLoading, isError, children }) => {
  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <ErrorPage />;
  }

  return <>{children}</>;
};

export default DataStatusHandler;
