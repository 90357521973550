import React, { FC, ReactNode } from 'react';
import classnames from 'classnames';

import SectionHeader from '../../components/SectionHeader/SectionHeader';

import './ContainerWithTitle.scss';

interface ContainerWithTitleProps {
  children: ReactNode;
  title: string | undefined;
  wrapperClass?: string;
  containerClass?: string;
}

const ContainerWithTitle: FC<ContainerWithTitleProps> = ({
  children,
  title,
  wrapperClass,
  containerClass,
}) => (
  <div className={classnames('ContainerWithTitle', wrapperClass)}>
    {title && <SectionHeader title={title} />}
    <div className={classnames('ContainerWithTitle-container', containerClass)}>{children}</div>
  </div>
);

export default ContainerWithTitle;
