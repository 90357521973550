import { useTranslation } from "react-i18next";
import React, { FC } from 'react';

import PageHead from "../PageHead/PageHead";
import './MaintenanceMode.scss';

const MaintenanceMode: FC<{ maintenanceMode: string }> = ({ maintenanceMode }) => {
  const { t } = useTranslation();

  return (
    <div className="MaintenanceMode">
      <PageHead />
      <h1 className="MaintenanceMode-title">{t('maintenance_mode_title')}</h1>
      {maintenanceMode}
    </div>
  );
};

export default MaintenanceMode;
