import React, { FC } from 'react';
import classnames from 'classnames';
import { Flex } from '@brightcove/studio-components';

import { analyticsEmitter } from '../../utils/analytics';
import config from '../../config';
import { SVGImage } from '../../assets/images';
import { RelatedResources } from '../../../types/generic';

type ResourcesPillProps = {
  key: string;
  className: string;
  content: RelatedResources;
};
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const getIconByType = (type: string) => {
  switch (type) {
    case 'external-link-square':
      return SVGImage.FileTypeLink;
    case 'download':
      return SVGImage.FileTypePdf;
    default:
      return SVGImage.FileTypeLink;
  }
};
const ResourcesPill: FC<ResourcesPillProps> = ({ content, className }) => {
  const { title, type, url, needsAuth } = content;
  const { supplementalDownload } = config.adobe_analytics.eventsToTrack;
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleOpenNewWindow = (url: string) => {
    window.open(url, '_blank');
  };

  return (
    <Flex className={className} flexDirection="row" justifyContent="space-between">
      <Flex className="w-75">
        <img className="icon" src={getIconByType(type)} alt="Resource type" />
        <div>
          <h6>{title}</h6>
          <p className="VideoDetailTab-resources-pill-truncate">
            {type === 'download' ? (
              url
            ) : needsAuth ? (
               url 
            ) : (
              <a
                onClick={() => analyticsEmitter.emit('sendCustomPageEvent', 'e', supplementalDownload)}
                href={url}
                target="_blank"
                rel="noreferrer"
                className="VideoDetailTab-resources-pill-link"
              >
                {url}
              </a>
            )}
          </p>
        </div>
      </Flex>
      <img
        className={classnames(needsAuth ? 'icon-disabled' : 'icon-enabled')}
        src={type === 'download' ? SVGImage.Download : SVGImage.newWindow}
        alt="download resource"
        onClick={() => {
          if (!needsAuth) {
            analyticsEmitter.emit('sendCustomPageEvent', 'd', supplementalDownload);
            handleOpenNewWindow(url);
          }
        }}
      />
    </Flex>
  );
};

export default ResourcesPill;
