import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { SiteConfig } from '../slices/configSlice';
import { encodeURLParams, getFullLanguageCode, getLanguage, supportedLanguages } from '../../utils';
import config from '../../config';
import { SettingsMetaData } from '../../../types/page';

const HEADER_USER_LANGAGE = 'user-language';

const getFullLanguage = (): string => {
  const language = getLanguage();

  return getFullLanguageCode(language, supportedLanguages) as string;
};

export const metadataApi = createApi({
  reducerPath: 'metadata',
  baseQuery: fetchBaseQuery({
    baseUrl: config.api_url,
    prepareHeaders: (headers) => {
      headers.set(HEADER_USER_LANGAGE, getFullLanguage());

      return headers;
    },
    paramsSerializer: encodeURLParams,
    credentials: 'include',
  }),
  endpoints: (builder) => ({
    getMeta: builder.query({
      query: () => 'meta',
    }),

    getConfigMeta: builder.query<SiteConfig, { lang?: string }>({
      query: ({ lang }) => ({
        url: 'config',
        params: {
          ts: Date.now(),
        },
        headers: {
          ...(lang ? { [HEADER_USER_LANGAGE]: lang } : {}),
        },
      }),
    }),

    getSettingsMeta: builder.query<SettingsMetaData, void>({
      query: () => 'settings',
    }),
  }),
});

export const { useGetMetaQuery, useGetConfigMetaQuery, useLazyGetConfigMetaQuery, useGetSettingsMetaQuery } =
  metadataApi;
