import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React, { FC, useMemo, useState } from 'react';

import Loading from '../Loading/Loading';
import Grid from '../Grid/Grid';
import DropdownMenu, { Option } from '../DropdownMenu/DropdownMenu';
import Button from '../Button/Button';
import { getUrlPath, isSameDomain, isValidCta } from '../../utils';
import { useGetSeasonDataQuery } from '../../store/services/page';
import { SeriesDetailSection } from '../../../types/section';
import { GridItem } from '../../../types/item';
import './SeriesDetail.scss';

interface SeriesDetailProps {
  section: SeriesDetailSection;
}

const SeriesDetail: FC<SeriesDetailProps> = ({ section }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const linearGradient = 'linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000000 78.12%)';
  const backgroundImage = `${linearGradient}, url(${section.image})`;
  const mappedSeasons = useMemo(
    () => section.seasons?.map(({ id, name }) => ({ value: id, label: name, locale_key: '' })),
    []
  );
  const [selectedSeason, setSelectedSeason] = useState<Option>(mappedSeasons?.[0]);

  const { data, isLoading } = useGetSeasonDataQuery(
    { id: selectedSeason?.value },
    { skip: !selectedSeason?.value }
  );

  const onCtaClick = (cta): void => {
    const destination = cta.url;

    if (isSameDomain(destination)) {
      navigate(getUrlPath(destination));
    } else {
      window.open(destination, '_blank');
    }
  };

  const renderContent = useMemo(
    () => (
      <>
        <p className="SeriesDetail-text-eyebrow">Available now</p>
        <p className="SeriesDetail-text-title">{section.name}</p>
        <p className="SeriesDetail-text-description">{section.description}</p>

        {isValidCta(section?.cta) && (
          <div className="SeriesDetail-buttons-container">
            <Button
              text={section.cta.text || section.cta.label}
              variant="primary"
              onClick={() => onCtaClick(section.cta)}
            />
          </div>
        )}
      </>
    ),
    []
  );

  const getSeasonData = (value): void => {
    setSelectedSeason(value);
  };

  return (
    <>
      <div className="SeriesDetail-desktop-container">
        <div className="SeriesDetail-desktop-container-left">{renderContent}</div>
        {section.image && (
          <img className="SeriesDetail-desktop-container-right" src={section.image} alt="Series image" />
        )}
      </div>

      <div className="SeriesDetail-mobile-container" style={{ backgroundImage }}>
        {renderContent}
      </div>

      {!!mappedSeasons?.length && (
        <div className="SeriesDetail-seasons-container">
          <div className="SeriesDetail-dropdown-title">
            {(t('season_dropdown_title') as string).toUpperCase()}
          </div>
          <DropdownMenu
            value={selectedSeason}
            btnLabel="Seasons"
            options={mappedSeasons}
            onSelect={getSeasonData}
          />
          {isLoading ? <Loading /> : data && <Grid items={data.episodes as GridItem[]} />}
        </div>
      )}
    </>
  );
};

export default SeriesDetail;
