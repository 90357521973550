import { useNavigate } from 'react-router';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import React, { FC, useMemo, useState } from 'react';
import { Accordion } from '@brightcove/studio-components';

import { Filter as FilterType } from '../../../types/search';

import Filter from './Filter/Filter';

import './Filters.scss';

type FiltersProps = {
  filters: FilterType[];
};

const Filters: FC<FiltersProps> = ({ filters }) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  const clearAllFilters = (): void => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete('filter');
    const queryString = searchParams.toString();
    const url = `${location.pathname}${queryString ? `?${queryString}` : ''}`;
    navigate(url, { replace: true });
  };

  const options = useMemo(
    () =>
      filters.map((filter) => (filter.options.length ? <Filter key={filter.name} filter={filter} /> : null)),
    []
  );

  const toggleAccordion = (): void => setIsOpen(!isOpen);

  return (
    <div className="Filters">
      <p className="Filters-clear-all-mobile" onClick={clearAllFilters}>
        {t('clear_all_filters')}
      </p>
      <Accordion
        action={null}
        className="Filters-accordion"
        variant="arrow"
        open={isOpen}
        onToggle={toggleAccordion}
        label={
          <div onClick={toggleAccordion} className="Filters-title-container">
            {t('view_filters')}
            <span className="Filters-chevron">{isOpen ? <MdExpandLess /> : <MdExpandMore />}</span>
          </div>
        }
      >
        <div className="Filters-container-mobile">{options}</div>
      </Accordion>
      <div className="Filters-wrapper">
        <p className="Filters-clear-all" onClick={clearAllFilters}>
          {t('clear_all_filters')}
        </p>
        <div className="Filters-container">{options}</div>
      </div>
    </div>
  );
};

export default Filters;
