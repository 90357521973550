import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import React, { FC } from 'react';

import { selectSiteConfig } from '../../store/selectors/configSelectors';
import { htmlStringToReactComponents } from '../../helpers/validators';

interface PageHeadProps {
  title?: string;
}

const PageHead: FC<PageHeadProps> = ({ title }) => {
  const { t } = useTranslation();
  const siteConfig = useSelector(selectSiteConfig);

  const nodes = htmlStringToReactComponents(siteConfig?.headerHtml);

  return (
    <Helmet>
      <title>{title || t('netapp_title')}</title>

      {!!nodes?.length && nodes.map((item) => item)}
    </Helmet>
  );
};

export default PageHead;
