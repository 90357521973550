import Settings from './icons/settings.svg';
import Search from './icons/search.svg';
import PlayWhiteIcon from './icons/play_white.svg';
import PlayIcon from './icons/play.svg';
import newWindow from './icons/new_window.svg';
import LiveIcon from './icons/live.svg';
import FileTypePdf from './icons/file_type_pdf.svg';
import FileTypeLink from './icons/file_type_link.svg';
import FileTypeImg from './icons/file_type_img.svg';
import FavoriteIcon from './icons/favorite.svg';
import FavoriteOffIcon from './icons/favorite-off.svg';
import Download from './icons/download.svg';
import ArrowForwardCarousel from './icons/arrow_forward_carousel.svg';
import ArrowForwardIcon from './icons/arrow_forward.svg';
import ArrowBackwardCarousel from './icons/arrow_backward_carousel.svg';
import NetAppLogo from './header/netapp_logo.svg';
import ErrorPage from './error_page.svg';
import DefaultThumbnail from './BlankThumbnail.png';

const SVGImage = {
  NetAppLogo,
  ArrowForwardIcon,
  ArrowForwardCarousel,
  ArrowBackwardCarousel,
  LiveIcon,
  Search,
  FavoriteIcon,
  FavoriteOffIcon,
  PlayIcon,
  PlayWhiteIcon,
  ErrorPage,
  FileTypePdf,
  FileTypeLink,
  FileTypeImg,
  Download,
  Settings,
  newWindow,
  DefaultThumbnail,
};

export { SVGImage };
