import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import debounce from 'lodash.debounce';

import ErrorPage from '../ErrorPage/ErrorPage';
import { DEFAULT_PAGE } from '../../utils';
import { useGetSearchPageQuery, useGetSearchResultsQuery } from '../../store/services/page';
import { selectPreviewToken } from '../../store/selectors/configSelectors';
import { selectValidated } from '../../store/selectors/authSelectors';
import Sections from '../../components/Sections/Sections';
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import SearchBar from '../../components/SearchBar/SearchBar';
import PageHead from '../../components/PageHead/PageHead';
import Loading from '../../components/Loading/Loading';
import Grid from '../../components/Grid/Grid';
import Filters from '../../components/Filters/Filters';
import { SearchSection, Section } from '../../../types/section';
import { SearchData } from '../../../types/search';
import { GridItem } from '../../../types/item';

import './Search.scss';

type Props = {
  customData?: SearchData;
};

const Search: FC<Props> = ({ customData }) => {
  const pvtk = useSelector(selectPreviewToken);
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const q = searchParams.get('q');
  const filter = searchParams.get('filter');
  const [searchResults, setSearchResults] = useState<GridItem[]>([]);
  const [page, setPage] = useState(DEFAULT_PAGE);
  const isValidated = useSelector(selectValidated);

  /**
   * This request gets all the page sections and the first results
   */
  const {
    data: initialData,
    isLoading: initialLoading,
    isFetching: isFetchingInitialData,
    isError: initialError,
    isUninitialized,
  } = useGetSearchPageQuery(
    {
      params: {
        page,
        ...(pvtk && { pvtk }),
        ...(q && { q: q.toLowerCase() }),
        ...(filter && { filter }),
      },
    },
    {
      skip: page !== DEFAULT_PAGE || isValidated === null || !!customData,
      refetchOnMountOrArgChange: true,
    }
  );

  const isFetchFinished = useMemo(
    () => !(initialLoading || isFetchingInitialData || isUninitialized),
    [initialLoading, isFetchingInitialData]
  );

  const sourceData = useMemo(() => customData || initialData, [customData, initialData]);

  const postResultsSections = useMemo(
    () => sourceData?.sections?.filter?.((item) => item.type && !['Search', 'SearchBar'].includes(item.type)),
    [sourceData]
  );

  /**
   * This request will be called when the user scrolls to the bottom of the site to load more results
   */
  const {
    data: moreData,
    isFetching: isFetchingMoreData,
    isLoading: moreLoading,
    isError: moreError,
  } = useGetSearchResultsQuery(
    {
      params: {
        ...(pvtk && { pvtk }),
        ...(q && { q: q.toLowerCase() }),
        ...(filter && { filter }),
        ...(page && { page }),
      },
    },
    {
      skip: page === DEFAULT_PAGE || isValidated === null || !!customData,
    }
  );

  const searchSection: SearchSection = sourceData?.sections.filter(
    (section: Section) => section.type === 'Search'
  )[0] as SearchSection;

  const totalPages = useMemo(
    () =>
      searchSection?.data?.totalCount && searchSection?.data?.pageSize
        ? Math.ceil(searchSection.data.totalCount / searchSection.data.pageSize) - 1
        : 0,
    [searchSection?.data?.totalCount, searchSection?.data?.pageSize]
  );

  const handleScroll = useCallback(
    debounce((): void => {
      if (
        window.innerHeight + document.documentElement.scrollTop <=
          document.documentElement.offsetHeight - 100 ||
        initialLoading ||
        moreLoading ||
        totalPages <= page
      )
        return;

      setPage(page + 1);
    }, 300),
    [page, initialLoading, moreLoading, totalPages]
  );

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  useEffect(() => {
    setPage(DEFAULT_PAGE);
  }, [q]);

  useEffect(() => {
    if (!isFetchFinished) {
      setSearchResults([]);
    } else {
      setSearchResults(searchSection?.data?.items || []);
    }
  }, [isFetchFinished, searchSection?.data]);

  useEffect(() => {
    if (moreData) {
      setSearchResults((prevSearchResults) => [...prevSearchResults, ...(moreData?.items || [])]);
    }
  }, [moreData]);

  if (pvtk) {
    return <ErrorPage />;
  }

  if (initialLoading) {
    return <Loading />;
  }

  if (initialError || moreError) {
    return <ErrorPage />;
  }

  const count = searchSection?.data?.totalCount;

  return (
    <>
      <PageHead title={sourceData?.name} />
      <div className="main-content main-content-search hide-overflow search-min-height">
        <SearchBar />

        <SectionTitle>
          <div className="search-title">
            <h2>
              {isFetchFinished && (
                <>{`${t('video_search_result', { count })} ${q?.length ? ` ${t('for')} ${q}` : ''}`}</>
              )}
            </h2>
          </div>
          {searchSection?.filters && <Filters filters={searchSection.filters} />}
        </SectionTitle>

        <div className="search-grid">
          {isFetchFinished ? <Grid items={searchResults} /> : <Loading />}
          {(moreLoading || isFetchingMoreData) && <Loading />}
        </div>

        <Sections sections={postResultsSections as Section[]} />

        {!!sourceData?.content && <div dangerouslySetInnerHTML={{ __html: sourceData?.content }} />}
      </div>
    </>
  );
};

export default Search;
