import React, { FC, ReactElement } from 'react';
import classnames from 'classnames';
import { Button as BrightcoveButton } from '@brightcove/studio-components';

import { ReactComponent as ArrowForward } from '../../assets/images/icons/arrow_forward.svg';

import './Button.scss';

interface ButtonProps {
  variant: 'primary' | 'secondary' | 'transparent' | 'link' | 'dark' | '';
  className?: string;
  text?: string;
  backgroundColor?: string;
  onClick: () => void;
  children?: ReactElement | string;
  disabled?: boolean;
  showArrow?: boolean;
  style?: object;
}

const Button: FC<ButtonProps> = ({
  variant,
  text,
  backgroundColor,
  onClick,
  className,
  children,
  disabled,
  style,
  showArrow = false,
}) => (
  <>
    <BrightcoveButton
      className={classnames(`Button Button-${disabled ? 'disabled' : variant}`, className)}
      onClick={(event) => {
        event.stopPropagation();
        onClick();
      }}
      disabled={disabled}
      style={{ ...style, backgroundColor }}
    >
      {text ? (
        <span className="Button-text" title={text}>
          {text}
        </span>
      ) : (
        children
      )}

      {(variant === 'primary' || showArrow) && <ArrowForward className="arrow-forward-icon ml-2" />}
    </BrightcoveButton>
  </>
);

export default Button;
