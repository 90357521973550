// import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';

import { setSiteConfig } from '../store/slices/configSlice';
import { setLogin, setValidated } from '../store/slices/authSlice';
import { useLazyGetConfigMetaQuery } from '../store/services/metadata';
import { selectUser } from '../store/selectors/authSelectors';
// import useValidate from '../hooks/useValidate';
import { useLanguageChange } from '../hooks/useLanguageChange';
import Loading from '../components/Loading/Loading';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const AuthenticationCheck = ({ children }) => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const [loading, setLoading] = useState(true);
  // const location = useLocation();
  // const { validateUser } = useValidate();
  const { changeLanguage } = useLanguageChange();
  const [getConfig, { data }] = useLazyGetConfigMetaQuery();

  // useEffect(() => {
  //   validateUser();
  // }, [location.pathname]);

  // useEffect(() => {
  //   if (user) {
  //     dispatch(setLogin(true));
  //     setLoading(false);
  //   } else {
  //     dispatch(setLogin(false));
  //     setLoading(false);
  //   }
  // }, [user]);

  useEffect(() => {
    setLoading(false);
    dispatch(setLogin(false));
    dispatch(setValidated(false));
  }, []);

  useEffect(() => {
    if (user) {
      changeLanguage(user.languagePref, true);
      getConfig({ lang: user.languagePref });
    }
  }, [user?.languagePref]);

  useEffect(() => {
    if (data) {
      dispatch(setSiteConfig(data));
    }
  }, [data]);

  return loading ? <Loading /> : children;
};

export default AuthenticationCheck;
