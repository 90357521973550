import React, { FC } from 'react';

import { useDynamicContentClickHandler } from '../../hooks/useDynamicContentClickHandler';
import { CustomSectionType } from '../../../types/section';

interface CustomSectionProps {
  section: CustomSectionType;
  name?: string;
}

const CustomSection: FC<CustomSectionProps> = ({ name, section: { templateRef, type } }) => {
  const { onDynamicContentClick } = useDynamicContentClickHandler();
  const dangerouslySetHTML = {
    __html: templateRef,
  };

  return (
    <div 
      dangerouslySetInnerHTML={dangerouslySetHTML} 
      onClick={(event) => {
        onDynamicContentClick(event, `${name || type} Section CTA`)
      }}
    />
  );
};

export default CustomSection;
