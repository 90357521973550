import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type FavoriteItem = {
  id: string;
};

export interface User {
  id: string;
  extId: string;
  name: string;
  email: string;
  accessRoles: string[];
  exp: number;
  iat: number;
  languagePref: string;
  favorites: {
    items: FavoriteItem[];
    page: number;
    pageSize: number;
    totalCount: number;
  };
}

export interface AuthState {
  token: string | null;
  isLogged: boolean | null;
  user: User | null;
  validated: boolean | null;
}

const initialState: AuthState = {
  token: null,
  isLogged: null,
  user: null,
  validated: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setValidateToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    setLogin: (state, action: PayloadAction<boolean>) => {
      state.isLogged = action.payload as boolean;
    },
    setUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
    },
    setValidated: (state, action: PayloadAction<boolean>) => {
      state.validated = action.payload;
    },
    clearToken: (state) => {
      state.token = null;
    },
  },
});

export const { setValidated, clearToken, setLogin, setUser } = authSlice.actions;

export default authSlice.reducer;
