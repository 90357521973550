import React, { FC } from 'react';
import { Flex } from '@brightcove/studio-components';

import { Speakers } from '../../../types/generic';
//import "./SpeakerPill.scss";
type SpeakersPillProps = {
  key: string;
  content: Speakers;
  className: string;
};

const SpeakerPill: FC<SpeakersPillProps> = ({ content, className }) => {
  const { name, title, imageUrl, bio } = content;

  return (
    <Flex className={className}>
      <div className="image-container">{!!imageUrl && <img src={imageUrl} />}</div>
      <div>
        <h6>{`${name} | ${title}`}</h6>
        <p>{bio}</p>
      </div>
    </Flex>
  );
};

export default SpeakerPill;
