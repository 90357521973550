import React, { FC, useEffect, useState } from 'react';

import Button from '../Button/Button';
import { generateUniqueId } from '../../utils';
import { RelatedResources, Speakers } from '../../../types/generic';

import SpeakerPill from './SpeakerPill';
import ResourcesPill from './ResourcesPill';

import './VideoDetailTab.scss';

type VideoDetailTabProps = {
  tabContent: TabContent[];
  className?: string;
};
type TabContent = {
  id: string;
  title: string;
  content: Speakers[] | RelatedResources[];
};
const VideoDetailTab: FC<VideoDetailTabProps> = ({ tabContent, className }) => {
  const [activeTab, setActiveTab] = useState<string>('speakers');

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleTabClick = (id: string) => {
    setActiveTab(id);
  };
  useEffect(() => {
    if (tabContent[0]?.content?.length > 0) {
      setActiveTab('speakers');
    } else if (tabContent[1]?.content?.length > 0) {
      setActiveTab('resources');
    }
  }, [tabContent]);

  return (
    <div className={`VideoDetailTab ${className}`}>
      <div className="VideoDetailTab-button-container mb-4">
        {tabContent.map((tab: TabContent) => {
          if (tab.content.length > 0) {
            return (
              <Button
                key={tab.title}
                text={tab.title}
                variant={activeTab === tab.id ? 'dark' : 'transparent'}
                onClick={() => handleTabClick(tab.id)}
              />
            );
          } else {
            null;
          }
        })}
      </div>
      <div>
        {tabContent
          .find((tab: TabContent) => tab.id === activeTab)
          ?.content.map((item) => {
            if (activeTab === 'speakers') {
              return (
                <SpeakerPill
                  key={generateUniqueId()}
                  content={item}
                  className="VideoDetailTab-speakers-pill"
                />
              );
            } else if (activeTab === 'resources') {
              return (
                <ResourcesPill
                  key={generateUniqueId()}
                  content={item}
                  className="VideoDetailTab-resources-pill"
                />
              );
            }
          })}
      </div>
    </div>
  );
};

export default VideoDetailTab;
