import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import React, { FC, useEffect, useState } from 'react';
import { Flex } from '@brightcove/studio-components';

import ErrorPage from '../ErrorPage/ErrorPage';
import COLORS from '../../styles/_colors.module.scss';
import { selectIsUserProfileFetched } from '../../store/selectors/userSelectors';
import { selectPreviewToken } from '../../store/selectors/configSelectors';
import * as ROUTES from '../../routes/routes';
import SectionHeader from '../../components/SectionHeader/SectionHeader';
import Button from '../../components/Button/Button';

import Favorites from './components/Favorites';
import ContinueWatching from './components/ContinueWatching';

import './MyProfile.scss';

const myProfileContentTypes = [
  {
    type: 'favorites',
    locale_label: 'my_profile_favorites',
  },
  {
    type: 'continue_watching',
    locale_label: 'my_profile_continue_watching',
  },
];

export const contentFilters = [
  {
    label: 'All Content',
    locale_key: 'content_filter_all_content',
    value: '',
  },
  {
    label: 'Episodes',
    locale_key: 'content_filter_episodes',
    value: 'EpisodeContent',
  },
  {
    label: 'Movies',
    locale_key: 'content_filter_movies',
    value: 'MovieContent',
  },
  {
    label: 'Series',
    locale_key: 'content_filter_series',
    value: 'SeriesContent',
  },
  {
    label: 'Seasons',
    locale_key: 'content_filter_seasons',
    value: 'SeasonContent',
  },
  {
    label: 'Events',
    locale_key: 'content_filter_events',
    value: 'StandardEvent',
  },
];

const MyProfile: FC = () => {
  const { t } = useTranslation();
  const isUserProfileFetched = useSelector(selectIsUserProfileFetched);
  const navigate = useNavigate();

  const [selectedType, setSelectedType] = useState(myProfileContentTypes[0]?.type);
  const pvtk = useSelector(selectPreviewToken);

  if (pvtk) {
    return <ErrorPage />;
  }

  useEffect(() => {
    if (isUserProfileFetched) {
      navigate(ROUTES.HOME);
    }
  }, [isUserProfileFetched]);

  return (
    <div className="MyProfile">
      <SectionHeader title={t('my_profile_header')} />
      <div className="MyProfile-content main-content">
        <Flex className="MyProfile-content-ctas" alignItems="center" justifyContent="center">
          {myProfileContentTypes.map(({ type, locale_label }, index) => {
            const btnLocaleLabel = t(locale_label);

            return (
              btnLocaleLabel && (
                <Button
                  key={index}
                  variant={selectedType === type ? 'dark' : ''}
                  text={btnLocaleLabel}
                  backgroundColor={selectedType === type ? COLORS.black : ''}
                  onClick={() => {
                    setSelectedType(type);
                  }}
                />
              )
            );
          })}
        </Flex>
        {selectedType === 'favorites' && <Favorites />}
        {selectedType === 'continue_watching' && <ContinueWatching />}
      </div>
    </div>
  );
};

export default MyProfile;
