import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import React, { FC, useMemo } from 'react';
import classNames from 'classnames';
import { Icon } from '@brightcove/studio-components';

// import { getFullLanguageCode, supportedLanguages } from '../../utils';
import {
  selectMaintenanceMode,
  selectPreviewToken,
  selectSiteBaseUrl,
  selectSiteConfig,
  // selectSiteLanguageLong,
} from '../../store/selectors/configSelectors';
import { selectLogged } from '../../store/selectors/authSelectors';
import * as ROUTES from '../../routes/routes';
import { logout } from '../../helpers/logout';
import config from '../../config';
import { SVGImage } from '../../assets/images';

import HamburgerMenu from './HamburgerMenu';

import './Header.scss';

const Header: FC = () => {
  const dispatch = useDispatch();
  const siteConfig = useSelector(selectSiteConfig);
  // const siteLanguageLong = useSelector(selectSiteLanguageLong);
  const isLoggedIn = useSelector(selectLogged);
  const headerCustomHtml = siteConfig?.headerMenu?.templateRef;
  const headerLinks = useMemo(() => siteConfig?.headerMenu?.data, [siteConfig]);
  const siteBaseUrl = useSelector(selectSiteBaseUrl);
  const maintenanceMode = useSelector(selectMaintenanceMode);
  const location = useLocation();
  const pvtk = useSelector(selectPreviewToken);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const conatinerClassName = classNames('Header', {
    ['Header-center']: maintenanceMode,
    ['Initiliazing']: !siteConfig,
  });

  // const getLoginUrl = (): string => {
  //   const fullLangCode = getFullLanguageCode(siteLanguageLong, supportedLanguages);
  //   const encodedLang = encodeURIComponent(fullLangCode as string);
  //   const encodedUri = encodeURIComponent(`${window.location.origin}/cb`);

  //   return `${config.api_url}/auth${ROUTES.LOGIN}?lang=${encodedLang}&redirectUri=${encodedUri}`;
  // };

  const dropdownLinks = useMemo(
    () => [
      { path: `${siteBaseUrl}${ROUTES.SETTINGS}`, label: t('header_settings') },
      // ...(isLoggedIn ? [{ path: `${siteBaseUrl}${ROUTES.MY_PROFILE}`, label: t('header_my_profile') }] : []),
      // ...(isLoggedIn ? [{ path: ROUTES.LOGOUT, label: t('header_log_out') }] : []),
      // ...(!isLoggedIn ? [{ path: getLoginUrl(), label: t('header_sign_in') }] : []),
    ],
    [isLoggedIn, siteBaseUrl, t]
  );

  const headerLinksMapped = useMemo(() => {
    const homeLink = {
      path: `${siteBaseUrl}/`,
      label: t('header_home'),
    };

    const eventsLink = {
      path: `${siteBaseUrl}/events`,
      label: t('header_events'),
    };

    const mapped = headerLinks?.length
      ? headerLinks.map(({ title, id, urlSafeName }) => ({
          path: `${siteBaseUrl}/page/${id}${urlSafeName ? `/${urlSafeName}` : ''}`,
          label: title,
        }))
      : [];

    mapped.unshift(homeLink);
    mapped.push(eventsLink);

    return mapped;
  }, [headerLinks, siteBaseUrl, siteConfig?.labels]);

  // const myProfileText = useMemo(
  //   () => (isLoggedIn ? t('header_my_profile') : t('header_sign_in')),
  //   [isLoggedIn, siteBaseUrl, siteConfig?.labels]
  // );

  const onDropdownLinkClick = (path) => () => {
    if (path === ROUTES.LOGOUT) {
      const destination = `${config.api_url}/auth${ROUTES.LOGOUT}?redirectUri=${encodeURIComponent(
        window.location.origin
      )}`;
      logout(dispatch);
      window.location.href = destination;
    } else if (path.includes('/login')) {
      if (window.location.pathname !== '/') {
        localStorage.setItem('originUrl', window.location.href);
      }

      window.location.href = path;
    } else {
      navigate(path);
    }
  };

  return (
    <div className={conatinerClassName}>
      {headerCustomHtml && <div dangerouslySetInnerHTML={{ __html: headerCustomHtml }} />}

      {!maintenanceMode && !headerCustomHtml && (
        <NavLink className="Header-logo-container" to={`${siteBaseUrl}/`}>
          <img className="Header-logo" src={SVGImage.NetAppLogo} alt={t('netapp_logo') || ''} />
        </NavLink>
      )}

      {!siteConfig && [1, 2].map((value) => <NavLink key={value} to="#" className="Header-link" />)}

      {siteConfig && !headerCustomHtml && !maintenanceMode && (
        <>
          <HamburgerMenu onClick={onDropdownLinkClick} links={[...headerLinksMapped, ...dropdownLinks]} />

          {!!headerLinksMapped?.length &&
            headerLinksMapped.map(({ path, label }) => (
              <NavLink
                key={label}
                to={path}
                onClick={onDropdownLinkClick(path)}
                className={classNames('Header-link', {
                  ['Header-link-active']: path === location.pathname,
                })}
              >
                {label}
              </NavLink>
            ))}

          <div className="Header-right-container">
            {!pvtk && (
              <NavLink to={`${siteBaseUrl}${ROUTES.SEARCH}`}>
                <Icon name="SEARCH" className="Header-search-icon" />
              </NavLink>
            )}
            <NavLink to={`${siteBaseUrl}${ROUTES.SETTINGS}`}>
              <img src={SVGImage.Settings} className="Header-settings-icon" />
            </NavLink>
            {/* <DropdownMenu
              label={myProfileText}
              className="Header-dropdown-menu"
              buttonProps={{
                look: isLoggedIn ? 'tertiary' : 'primary',
                className: isLoggedIn ? 'Header-dropdown-menu-button' : 'Header-dropdown-menu-button-sign-in',
              }}
              testName="menu-modules"
            >
              <>
                {!!dropdownLinks?.length &&
                  dropdownLinks?.map(({ path, label }) => (
                    <DropdownMenuItem
                      key={label}
                      className="Header-dropdown-menu-item"
                      onClick={onDropdownLinkClick(path, label)}
                    >
                      {label}
                    </DropdownMenuItem>
                  ))}
              </>
            </DropdownMenu> */}
          </div>
        </>
      )}

      {maintenanceMode && (
        <NavLink to={`${siteBaseUrl}/`}>
          <img className="Header-logo" src={SVGImage.NetAppLogo} alt={t('netapp_logo') || ''} />
        </NavLink>
      )}
    </div>
  );
};

export default Header;
