import { useTranslation } from 'react-i18next';
import React, { FC, useState } from 'react';
import './ForgotPasswordModal.scss';

import TextInput from '../ControlledComponents/TextInput';
import Button from '../Button/Button';

import GenericModal from './GenericModal';

interface ForgotPasswordModalProps {
  show: boolean;
  onClose: () => void;
}
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const GenericTitle = ({ title, description }) => (
  <div className="generic-title">
    <h2>{title}</h2>
    <h5>{description}</h5>
  </div>
);
const ForgotPasswordModal: FC<ForgotPasswordModalProps> = ({ show, onClose }) => {
  const [email, setEmail] = useState<string>('');
  const { t } = useTranslation();

  return (
    <div className="ForgotPasswordModal">
      <GenericModal
        title={<GenericTitle title={t('login_forgot_title')} description={t('login_forgot_description')} />}
        show={show}
        onClose={onClose}
      >
        <div className="ForgotPasswordModal-form">
          <div className="ForgotPasswordModal-form-group">
            <label form="login">{t('login_placeholder_email')}</label>
            <TextInput
              onChange={setEmail}
              value={email}
              placeholder={t('login_placeholder_email') as string}
              className="input-error"
            ></TextInput>
            <span className="error-message">{t('login_error_message')}</span>
          </div>
          <Button
            className="mt-8 ForgotPasswordModal-form-button"
            variant="primary"
            text={t('login_label_send_link') as string}
            // eslint-disable-next-line no-console
            onClick={() => console.log('send mail')}
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          />
          <div className="ForgotPasswordModal-back-button mt-4">
            <a href="#" onClick={() => onClose()}>
              {t('login_label_back_sign_in')}
            </a>
          </div>
        </div>
      </GenericModal>
    </div>
  );
};

export default ForgotPasswordModal;
