import { useSelector } from 'react-redux';
import { IoMdClose } from 'react-icons/io';
import React, { FC, useState } from 'react';

import { selectSiteConfig } from '../../store/selectors/configSelectors';
import { useDynamicContentClickHandler } from '../../hooks/useDynamicContentClickHandler';
import './ServiceMessage.scss';

const ServiceMessage: FC<{ message: string }> = ({ message }) => {
  const [open, setOpen] = useState(true);
  const { onDynamicContentClick } = useDynamicContentClickHandler();

  return open ? (
    <div key={message} className="Service-message">
      <p 
        className="Service-message-text" 
        dangerouslySetInnerHTML={{ __html: message }} 
        onClick={(event) => {
          onDynamicContentClick(event, 'Service Message')
        }}
      />
      <div className="Service-message-icon-container">
        <IoMdClose className="Service-message-icon" onClick={() => setOpen(false)} />
      </div>
    </div>
  ) : null;
};

const ServiceMessages: FC = () => {
  const siteConfig = useSelector(selectSiteConfig);
  const serviceMessages = siteConfig?.serviceMessages;

  return serviceMessages?.length ? (
    <div>
      {serviceMessages.map((message) => (
        <ServiceMessage key={message} message={message} />
      ))}
    </div>
  ) : null;
};

export default ServiceMessages;
