import { useSelector } from 'react-redux';
import React, { FC, useEffect, useState } from 'react';
import './UpcomingEventDetail.scss';
import moment from 'moment';

import { getTypeOfEvent } from '../../../../utils';
import { selectSiteConfig } from '../../../../store/selectors/configSelectors';
import { SVGImage } from '../../../../assets/images';
import { LocaleName, Video } from '../../../../../types/generic';

interface UpcomingEventDetailProps {
  upcomingEvent: Video;
}
const getTimeFromDate = (date: string | null, showDate = false): string => {
  if (!date || date === 'Invalid date') return '';

  const dateMoment = moment(date);

  return `${dateMoment.format(showDate ? 'MM/DD | hh:mmA' : 'hh:mmA')}`;
};

const getNameByLanguage = (names: LocaleName[], selectedLanguage = 'en-us'): LocaleName | undefined =>
  names.find((name: LocaleName) => name.language === selectedLanguage);

const UpcomingEventDetail: FC<UpcomingEventDetailProps> = ({ upcomingEvent }) => {
  const siteConfig = useSelector(selectSiteConfig);
  const { startDate, endDate, name, duration, description } = upcomingEvent;
  const eventDuration = duration ? duration : 0;
  const finishDate = endDate?.length ? endDate : moment(startDate).add(eventDuration, 'seconds');
  const [typeEvent, setTypeEvent] = useState(getTypeOfEvent(startDate, finishDate.toString()));

  useEffect(() => {
    const intervalId = setInterval(() => {
      const updatedTypeEvent = getTypeOfEvent(startDate, finishDate.toString());
      setTypeEvent(updatedTypeEvent);
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [startDate, finishDate]);

  return (
    <div
      className={`UpcomingEventDetail ${typeEvent === 'liveEvent' ? 'UpcomingEventDetail-live-event' : ''}`}
    >
      <div className="UpcomingEventDetail-date">
        {getTimeFromDate(startDate, true)} {getTimeFromDate(finishDate.toString()).length ? '-' : ''}{' '}
        {getTimeFromDate(finishDate.toString())}
      </div>
      <div className="UpcomingEventDetail-live-message">
        {typeEvent === 'liveEvent' ? (
          <>
            <img src={SVGImage.LiveIcon} alt="Live icon" />
            Live
          </>
        ) : null}
      </div>

      <div className="py-3">
        <div className="UpcomingEventDetail-title">
          {typeof name === 'string' ? name : getNameByLanguage(name, siteConfig?.language)?.value}
        </div>

        {!!description && <div className="UpcomingEventDetail-description">{description}</div>}
      </div>
    </div>
  );
};

export default UpcomingEventDetail;
