import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { setLogin, setUser, setValidated, User } from '../store/slices/authSlice';
import { selectLogged, selectUser } from '../store/selectors/authSelectors';
import { HOME } from '../routes/routes';
import config from '../config';

interface UseValidateReturn {
  validateUser: (code?: string | null, state?: string | null, redirect?: boolean) => Promise<void>;
}

const getProfile = async (): Promise<User | null> => {
  const response = await fetch(`${config.api_url}/user/profile`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
  });

  if (response.ok) {
    return (await response.json()) as User;
  }

  return null;
};

const useValidate = (): UseValidateReturn => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoggedIn = useSelector(selectLogged);
  const user = useSelector(selectUser);

  const navigateToOriginalRoute = (): void => {
    const originUrl = localStorage.getItem('originUrl');

    if (originUrl) {
      window.location.href = originUrl;
      localStorage.removeItem('originUrl');
    } else {
      navigate(HOME);
    }
  };

  const validateUser = async (
    code?: string | null,
    state?: string | null,
    redirect = false
  ): Promise<void> => {
    if (isLoggedIn || (code && state) || isLoggedIn === null) {
      try {
        let body: { code: string; state: string } | null = null;

        if (code && state) {
          body = {
            code,
            state,
          };
        }

        const response = await fetch(`${config.api_url}/auth/validate`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
          ...(body && { body: JSON.stringify(body) }),
        });

        if (response.ok) {
          dispatch(setLogin(true));

          if (!user) {
            const userResponse = await getProfile();

            if (userResponse) {
              dispatch(setUser(userResponse));
              dispatch(setValidated(true));
            }
          }

          if (redirect) navigateToOriginalRoute();
        } else {
          dispatch(setValidated(false));
        }
      } catch (error) {
        dispatch(setValidated(false));
      }
    }
  };

  return { validateUser };
};

export default useValidate;
