import { useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import React, { FC, useMemo } from 'react';
import { Flex } from '@brightcove/studio-components';

import SearchInput from '../SearchInput/SearchInput';
import { selectPreviewToken, selectSiteBaseUrl } from '../../store/selectors/configSelectors';

import './SearchBar.scss';

const SearchBar: FC = () => {
  const navigate = useNavigate();
  const siteBaseUrl = useSelector(selectSiteBaseUrl);
  const pvtk = useSelector(selectPreviewToken);
  const [searchParams] = useSearchParams();
  const q = searchParams.get('q');

  const onSearch = (val: string): void => {
    const searchValue = val.length > 2 ? `?q=${encodeURIComponent(val)}` : '';
    navigate(`${siteBaseUrl?.length ? siteBaseUrl : ''}/search${searchValue}`);
  };

  const searchInput = useMemo(
    () =>
      !pvtk && <SearchInput value={q || ''} key="search" onSearch={onSearch} className="SearchBar-search" />,
    [q, pvtk]
  );

  return (
    <Flex className="SearchBar pl-6 pr-6" justifyContent="center">
      {searchInput}
    </Flex>
  );
};

export default SearchBar;
