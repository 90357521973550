import { EventEmitter } from 'events';

import config from '../config';

export const analyticsEmitter = new EventEmitter();

export const onTrackLinkClick = (id: string, container: string, destination: string): void => {
  const {
    linkClickVars,
    eventsToTrack: { linkClick },
  } = config.adobe_analytics;

  const trackObject = {
    [linkClickVars.linkId]: id,
    [linkClickVars.linkContainer]: container,
    [linkClickVars.linkDestination]: destination,
  };

  analyticsEmitter.emit('sendCustomPageEvent', 'CTA Link Clicks', linkClick, trackObject);
};

export const onTrackCTAClick = (id: string, container: string, destination = ''): void => {
  const {
    ctaClickVars,
    eventsToTrack: { ctaClick },
  } = config.adobe_analytics;

  const trackObject = {
    [ctaClickVars.ctaId]: id,
    [ctaClickVars.ctaContainer]: container,
    [ctaClickVars.ctaDestination]: destination,
  };

  analyticsEmitter.emit('sendCustomPageEvent', 'CTA Link Clicks', ctaClick, trackObject);
};
