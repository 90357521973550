import { useParams } from 'react-router';
import React, { FC } from 'react';

import { useGetCollectionDetailQuery } from '../../store/services/collection';
import PageHead from '../../components/PageHead/PageHead';
import Loading from '../../components/Loading/Loading';
import Grid from '../../components/Grid/Grid';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {}

export const CollectionDetail: FC<Props> = () => {
  const { id } = useParams<{ id: string }>();
  const { data, isFetching } = useGetCollectionDetailQuery(id as string);

  return (
    <div>
      <PageHead title={data?.name} />
      <div className="main-content p-5">
        <Grid items={data?.items || []} columnCount={3} gridName={data?.name} />
        {isFetching && <Loading />}
      </div>
    </div>
  );
};

export default CollectionDetail;
