import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import {
  getLanguageCode,
  getLanguageFromUrl,
  getLanguageRoute,
  isLanguageChangeBlocked,
  isSameLanguage,
} from '../utils';
import { setSiteLanguageInfo } from '../store/slices/configSlice';
import { selectSiteLanguage } from '../store/selectors/configSelectors';
import { selectLogged } from '../store/selectors/authSelectors';

type UseLanguageChange = {
  changeLanguage: (lang: string, forceChange?: boolean) => void;
};

const LANG_BLOCK_KEY = 'langBlockedUntil';

const isCallbackRoute = (): boolean => {
  const pathname = window.location.pathname;
  const pathArr = pathname.split('/');
  const firstPathValue = pathArr?.[1]?.split('?')?.[0];

  return firstPathValue === 'cb';
};

export const useLanguageChange = (): UseLanguageChange => {
  const currentLang = useSelector(selectSiteLanguage);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoggedIn = useSelector(selectLogged);

  const changeLanguage = (newLanguage: string, forceChange = false): void => {
    const newLanguageCode = getLanguageCode(newLanguage);
    const languageInPath = getLanguageFromUrl();
    const currentIsSameThanNew = isSameLanguage(currentLang, newLanguageCode);
    const isChangeBlocked = isLanguageChangeBlocked();
    const isCallback = isCallbackRoute();

    if (
      !isCallback &&
      ((!languageInPath && !isChangeBlocked && !currentIsSameThanNew) ||
        (forceChange && !currentIsSameThanNew))
    ) {
      if (forceChange) {
        if (isLoggedIn) {
          localStorage.setItem(LANG_BLOCK_KEY, moment().add(5, 'minutes').toISOString());
        } else {
          localStorage.removeItem(LANG_BLOCK_KEY);
        }
      }

      localStorage.setItem('lang', newLanguageCode);
      dispatch(setSiteLanguageInfo(newLanguageCode));
      const languageRoute = getLanguageRoute(newLanguage);

      languageRoute && navigate(languageRoute, { replace: true }); // tslint:disable-line
    }
  };

  return { changeLanguage };
};
