import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React, { FC } from 'react';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Tile from '../Tile/Tile';
import BREAK_POINTS from '../../styles/_breakpoints.module.scss';
import './Carousel.scss';
import { useGetPageUrl } from '../../hooks/useGetPageUrl';
import { useDimensions } from '../../hooks/useDimensions';
import { SVGImage } from '../../assets/images';
import { CarouselSection } from '../../../types/section';
import { TileItem } from '../../../types/item';

import Arrow from './Arrow';

const getItemsToShow = (width): 1 | 2 | 3 => {
  const tablet = parseInt(BREAK_POINTS.tabletPortrait, 10);
  const mobile = parseInt(BREAK_POINTS.mobile, 10);

  if (width > tablet) {
    return 3;
  } else if (width <= tablet && width > mobile) {
    return 2;
  } else {
    return 1;
  }
};

interface CarouselProps {
  name?: string;
  section: CarouselSection;
}

const Carousel: FC<CarouselProps> = ({
  section: {
    name,
    type,
    id,
    content,
    data: { items },
  },
}) => {
  const { t } = useTranslation();
  const url = useGetPageUrl({ id, pageId: content.id, type });
  const { width } = useDimensions();
  const settings = {
    dots: false,
    infinite: false,
    arrows: true,
    speed: 500,
    className: 'Carousel-slider',
    slidesToScroll: getItemsToShow(width),
    slidesToShow: getItemsToShow(width),
    prevArrow: (
      <Arrow type="prev">
        <img src={SVGImage.ArrowBackwardCarousel} alt="NetApp Tv Arrow" />
      </Arrow>
    ),
    nextArrow: (
      <Arrow type="next">
        <img src={SVGImage.ArrowForwardCarousel} alt="NetApp Tv Arrow" />
      </Arrow>
    ),
  };

  if (items?.length === 0) {
    return null;
  }

  return (
    <div className="carrousel-container">
      <div className="Carousel-header-container">
        {name && <h2 className="Carousel-header">{name}</h2>}
        {url && (
          <Link to={url} className="Carousel-view-more">
            {t('section_view_more')}
          </Link>
        )}
      </div>
      <Slider {...settings}>
        {items?.map((item) => (
          <div key={item.id} className="mb-8">
            <div className="mr-4">
              <Tile key={item.id} {...(item as TileItem)} />
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Carousel;
