import { useSelector } from 'react-redux';
import React, { FC } from 'react';

import { useGetErrorPageQuery } from '../../store/services/page';
import { selectPreviewToken } from '../../store/selectors/configSelectors';
import { selectValidated } from '../../store/selectors/authSelectors';
import { useDisablePage } from '../../hooks/useDisablePage';
import { validateHTML } from '../../helpers/validators';
import Sections from '../../components/Sections/Sections';
import SearchBar from '../../components/SearchBar/SearchBar';
import PageHead from '../../components/PageHead/PageHead';
import Loading from '../../components/Loading/Loading';
import { Section } from '../../../types/section';
import { ErrorData } from '../../../types/page';
import './ErrorPage.scss';

type Props = {
  customData?: ErrorData;
};

const ErrorPage: FC<Props> = ({ customData }) => {
  const pvtk = useSelector(selectPreviewToken);
  const isValidated = useSelector(selectValidated);
  const { data, isLoading } = useGetErrorPageQuery(pvtk ? { params: { pvtk } } : {}, {
    refetchOnMountOrArgChange: true,
    skip: isValidated === null || !!customData,
  });

  const sourceData = customData || data;

  useDisablePage(!!(sourceData?.isPreview && pvtk));

  if (isLoading) {
    return <Loading />;
  }

  const additionalHtml = validateHTML(sourceData?.content);

  return (
    <div className="ErrorPage">
      <PageHead title={sourceData?.name} />

      <SearchBar />

      <div className="ErrorPageContent">
        <Sections sections={sourceData?.sections as Section[]} />

        {!!additionalHtml && (
          <div className="additional-html" dangerouslySetInnerHTML={{ __html: additionalHtml }} />
        )}
      </div>
    </div>
  );
};

export default ErrorPage;
