import { useSelector } from 'react-redux';
import React, { FC } from 'react';

import ErrorPage from '../ErrorPage/ErrorPage';
import { useGetEventLandingPageQuery } from '../../store/services/page';
import { selectPreviewToken } from '../../store/selectors/configSelectors';
import { selectValidated } from '../../store/selectors/authSelectors';
import { useDisablePage } from '../../hooks/useDisablePage';
import Sections from '../../components/Sections/Sections';
import PageHead from '../../components/PageHead/PageHead';
import Loading from '../../components/Loading/Loading';
import { EventData } from '../../../types/page';

type Props = {
  customData?: EventData;
};

const Event: FC<Props> = ({ customData }) => {
  const pvtk = useSelector(selectPreviewToken);
  const isValidated = useSelector(selectValidated);

  const { data, isLoading, isError } = useGetEventLandingPageQuery(pvtk ? { params: { pvtk } } : {}, {
    refetchOnMountOrArgChange: true,
    skip: isValidated === null || !!customData,
  });

  const sourceData = customData || data;

  useDisablePage(!!(sourceData?.isPreview && pvtk));

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <ErrorPage />;
  }

  return (
    <div>
      <PageHead title={sourceData?.name} />
      <div className="main-content">
        <Sections sections={sourceData?.sections} />
      </div>
    </div>
  );
};

export default Event;
