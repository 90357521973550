import { useLocation, useNavigate } from 'react-router-dom';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';
import React, { FC, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import { Filter as FilterType, FilterOption } from '../../../../types/search';

import './Filter.scss';

interface FilterProps {
  filter: FilterType;
}

const Filter: FC<FilterProps> = ({ filter }) => {
  const [options] = useState<FilterOption[]>(filter.options);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [isOpen, setIsOpen] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const filterKey = filter.name;

  /**
   * This method adds and removes filters to the URL
   * The format is &filter=filter1:value1,value2;filter2:value1,value2
   * Read the comments inside the method to get a more clear understanding
   *
   * @param selectedOption
   */
  const updateURL = (selectedOption: string): void => {
    const searchParams = new URLSearchParams(location.search);

    let filterValue = searchParams.get('filter');

    // { product: ['Test 1', 'Test 2'], industry: ['Test 1', 'Test 2']}
    const existingFilters: Record<string, string[]> = {};

    // Parse the existing filters from the URL (?filter=)
    if (filterValue) {
      // filters are separated by ;
      const filterSegments = filterValue.split(';');
      filterSegments.forEach((segment) => {
        // filter name is followed by a : and all that goes after that are values (filter1:value1,value2)
        // split the string at the first occurrence of ':'
        const [filterSegment, valueSegment] = segment.split(/:(.+)/);
        // values are separated by , so here we split it and decode each one
        existingFilters[filterSegment] = valueSegment?.split(',').map((option) => decodeURIComponent(option));
      });
    }

    // if the selectedOption is the same as one that already exists then it should remove it
    let isRemoving = false;

    // Update the selected options for the current filter
    if (existingFilters[filterKey]) {
      // Remove the selected option if already present
      existingFilters[filterKey] = existingFilters[filterKey].filter((option) => {
        if (option === selectedOption) {
          isRemoving = true;

          return false;
        }

        return true;
      });

      // Remove the filter if no values are selected for that filter
      if (existingFilters[filterKey].length === 0) {
        delete existingFilters[filterKey];
      }
    } else {
      // Create empty array if the filter doesn't exist but should
      existingFilters[filterKey] = [];
    }

    // Add the selected option to the filter array only if it's not being removed
    if (!isRemoving) {
      existingFilters[filterKey]?.push(selectedOption);
    }

    // Construct the updated filter value to comply with the format filter=filter:value1,value2;filter2:value1,value2
    const updatedFilters = Object.entries(existingFilters).map(([currentFilter, options]) => {
      const encodedOptions = options.map((option) => option);

      return `${currentFilter}:${encodedOptions.join(',')}`;
    });

    filterValue = updatedFilters.join(';');

    // Update the filter parameter in the URL
    if (filterValue) {
      searchParams.set('filter', filterValue);
    } else {
      searchParams.delete('filter');
    }

    const queryString = searchParams.toString();
    // generate the url, so it can navigate to it and re trigger the search call with all the filters applied
    const url = `${location.pathname}${queryString ? `?${queryString}` : ''}`;
    navigate(url, { replace: true });
  };

  /**
   * Every time the user clicks a checkbox we update the state and the url
   * @param option
   */
  const toggleOption = (option: string): void => {
    setSelectedOptions((prevSelectedOptions) =>
      prevSelectedOptions.includes(option)
        ? prevSelectedOptions.filter((selectedOption) => selectedOption !== option)
        : [...prevSelectedOptions, option]
    );

    updateURL(option);
  };

  const toggleDropdown = (): void => {
    setIsOpen(!isOpen);
  };

  const extractFiltersFromURL = (): void => {
    const searchParams = new URLSearchParams(location.search);
    const filterValue = searchParams.get('filter');

    if (filterValue) {
      const filters = filterValue.split(';');
      filters.forEach((f) => {
        const [categoryValue, selectedOptionLabels] = f.split(/:(.+)/);

        if (filter.name === categoryValue) {
          const selectedOptions = selectedOptionLabels.split(',').map((label) => decodeURIComponent(label));
          setSelectedOptions((prevSelectedOptions) => [...prevSelectedOptions, ...selectedOptions]);
        }
      });
    } else {
      setSelectedOptions([]);
    }
  };

  useEffect(() => {
    extractFiltersFromURL();
  }, [location]);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent): void => {
      const target = event.target as Node;
      const dropdown = dropdownRef.current;
      const button = buttonRef.current;

      if (button && dropdown && !button.contains(target) && !dropdown.contains(target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  return (
    <div className={classNames('Filter', { ['open']: isOpen })}>
      <button
        className={classNames('Filter-button', {
          'Filter-button--open': isOpen,
          'Filter-button--closed': !isOpen,
        })}
        ref={buttonRef}
        onClick={toggleDropdown}
      >
        {filter.display_name}
        <span className="Filter-chevron">
          <MdExpandLess className={classNames({ ['Filter-chevron-hide']: !isOpen })} />
          <MdExpandMore className={classNames({ ['Filter-chevron-hide']: isOpen })} />
        </span>
      </button>

      {isOpen && (
        <div className="Filter-dropdown" ref={dropdownRef}>
          {options.map(({ label, value }) => (
            <label
              key={value}
              className={classNames('Filter-option', {
                ['Filter-option--selected']: selectedOptions.includes(value),
              })}
            >
              <input
                type="checkbox"
                className="custom-checkbox"
                checked={selectedOptions.includes(value)}
                onChange={() => {
                  toggleOption(value);
                }}
              />
              <span title={label}>{label}</span>
            </label>
          ))}
        </div>
      )}
    </div>
  );
};

export default Filter;
