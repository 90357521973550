import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import React, { FC, useState } from 'react';
import './LoginPage.scss';
import { Flex, Toggle } from '@brightcove/studio-components';

import { authErrorMockData } from '../../store/services/mock/authMockData';
import { selectSiteBaseUrl } from '../../store/selectors/configSelectors';
import * as ROUTES from '../../routes/routes';
import ForgotPasswordModal from '../../components/Modals/ForgotPasswordModal';
import TextInput from '../../components/ControlledComponents/TextInput';
import Button from '../../components/Button/Button';
import { SVGImage } from '../../assets/images';

const LoginPage: FC = () => {
  const { t } = useTranslation();
  const siteBaseUrl = useSelector(selectSiteBaseUrl);
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showForgotPassModal, setShowForgotPassModal] = useState<boolean>(false);
  const [rememberMe, setRememberMe] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  React.useEffect(() => {
    const rememberMeValue = localStorage.getItem('rememberMe');
    const rememberMe = rememberMeValue?.length ? JSON.parse(rememberMeValue) : null;

    if (rememberMe) {
      const email = localStorage.getItem('email');
      setRememberMe(true);
      setEmail(email?.length ? JSON.parse(email) : '');
    }
  }, []);

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handlerSubmit = () => {
    localStorage.setItem('rememberMe', JSON.stringify(rememberMe));

    if (rememberMe) {
      localStorage.setItem('email', JSON.stringify(email));
    } else {
      localStorage.setItem('email', '');
    }

    if (!authErrorMockData.success) {
      setHasError(true);
      setErrorMessage(authErrorMockData.message);
    }
  };

  return (
    <div className="LoginPage">
      <ForgotPasswordModal show={showForgotPassModal} onClose={() => setShowForgotPassModal(false)} />
      <div className="form">
        <Flex justifyContent="center" className="mb-12 pb-4 logo-desktop">
          <img className="login-logo" src={SVGImage.NetAppLogo} alt={t('netapp_logo') as string} />
        </Flex>
        <h2>{t('login_title')}</h2>
        <div className="form-group">
          <label form="login">{t('login_label_login')}</label>
          <TextInput
            onChange={setEmail}
            value={email}
            placeholder={t('login_placeholder_email') as string}
            className={hasError ? 'input-error' : ''}
          ></TextInput>
          <span className="error-message">{errorMessage}</span>
        </div>
        <div className="form-group">
          <label form="password">{t('login_label_password')}</label>
          <TextInput
            onChange={setPassword}
            value={password}
            placeholder={t('login_label_password') as string}
            type="password"
            togglePassword
          ></TextInput>
        </div>
        <Flex justifyContent="space-between" alignItems="center">
          <div className="toggle-remember">
            <Toggle
              value={rememberMe}
              onChange={(value) => setRememberMe(value)}
              onLabel={t('login_remember_me') as string}
              offLabel={t('login_remember_me') as string}
            ></Toggle>
          </div>
          <div className="forgot-password">
            <a href="#" onClick={() => setShowForgotPassModal(true)}>
              {t('login_forgot_password')}
            </a>
          </div>
        </Flex>
        <Button
          className="mt-8 button-center-this"
          variant="primary"
          text={t('login_label_login') as string}
          onClick={handlerSubmit}
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        />
        <Flex justifyContent="center" alignItems="center" className="mt-2">
          <label>{t('login_have_account')}</label>
          <label className="forgot-password ml-1">
            <a href={`${siteBaseUrl}${ROUTES.SIGN_UP}`} onClick={() => setShowForgotPassModal(true)}>
              {t('login_sign_up')}
            </a>
          </label>
        </Flex>
      </div>
      <div className="login-image"></div>
    </div>
  );
};
export default LoginPage;
